import Autocomplete from "@mui/joy/Autocomplete";
import AutocompleteOption from "@mui/joy/AutocompleteOption";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import ButtonGroup from "@mui/joy/ButtonGroup";
import Card from "@mui/joy/Card";
import FormControl from "@mui/joy/FormControl";
import FormLabel, { formLabelClasses } from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import ListItemContent from "@mui/joy/ListItemContent";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import { trackProfileSetup } from "api/tracking";
import { Profile, User, updateProfile, useUser } from "api/users";
import { MixpanelInit } from "components/Mixpanel/MixpanelInit";
import { AuthContext, RequireAuth } from "context/Auth";
import { getCountryDataList } from "countries-list";
import _ from "lodash";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { useSearchParams } from "react-router-dom";


interface FormElements extends HTMLFormControlsCollection {
    name: HTMLInputElement;
    lastName: HTMLInputElement;
    roleDesc: HTMLInputElement;
}

interface ProfileFormElement extends HTMLFormElement {
    readonly elements: FormElements;
}

export const ProfileSetupPage = () => {
    const [country, setCountry] = useState<string | null>()
    const roles = ['Marketing Expert', 'Data Analyst', 'Content Creator', 'Other']
    const [role, setRole] = useState<string>(roles[0])

    const [searchParams] = useSearchParams();
    const sessionId = searchParams.get('sid')
    const flowId = searchParams.get('fid')

    const { isLoading, data: user } = useUser({
        onSuccess: (data: User) => {
            if (user?.profile?.data['country']?.toString()) {
                setCountry(user?.profile?.data['country']?.toString())
            }
        }
    })

    const { isLoading: isSaving, mutateAsync: saveProfile } = useMutation(updateProfile, {
        onSuccess: async (data) => {
            if (user) {
                await trackProfileSetup(user)
            }
            window.location.href = flowId && sessionId ? `/flows/setup/${flowId}/${sessionId}` : '/'
        }
    })

    if (isLoading)
        return (<></>)


	const countries = getCountryDataList();
    const selectedCountry = _.find(countries, (c) => c.name === country)

    return (<>
        <RequireAuth mode='redirect'>
            <Box sx={{ display: 'flex', minHeight: '100dvh' }}>
                <Box
                    sx={{
                        pt: {
                            xs: 2,
                            sm: 2,
                            md: 2,
                        },
                        pb: {
                            xs: 2,
                            sm: 2,
                            md: 2,
                        },
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: 0,
                        height: '100dvh',
                        gap: 1,
                        overflow: 'auto',
                        '& form': {
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                        }
                    }}
                >
                    <Box
                        component="main"
                        sx={{
                            my: 'auto',
                            py: 2,
                            pb: 5,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                            width: 500,
                            maxWidth: '100%',
                            mx: 'auto',
                            borderRadius: 'sm',
                            '& form': {
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                            },
                            [`& .${formLabelClasses.asterisk}`]: {
                                visibility: 'hidden',
                            },
                        }}
                    >
                        <Card>
                            <Stack gap={4} sx={{ mb: 2 }}>
                                <Stack gap={1}>
                                    <Typography level="h3">Complete your Pavuk AI profile</Typography>
                                    <Typography level="body-sm">
                                        Just a few simple questions to optimize your experience
                                    </Typography>
                                </Stack>
                            </Stack>
                            <Stack gap={4} sx={{ mt: 2 }}>
                                <form
                                    onSubmit={(event: React.FormEvent<ProfileFormElement>) => {
                                        event.preventDefault();
                                        const formElements = event.currentTarget.elements;

                                        const data: Profile = {
                                            name: formElements.name.value,
                                            lastName: formElements.lastName.value,
                                            data: {
                                                'country': country,
                                                'role': role !== 'Other' ? role : formElements.roleDesc?.value
                                            }
                                        };

                                        saveProfile(data)
                                    }}
                                >
                                    <FormControl required>
                                        <FormLabel>Name</FormLabel>
                                        <Input type="text" name="name" defaultValue={user?.profile?.name} disabled={isSaving} slotProps={{ input: { 'maxLength': 50 } }} />
                                    </FormControl>
                                    <FormControl required>
                                        <FormLabel>Last name</FormLabel>
                                        <Input type="text" name="lastName" defaultValue={user?.profile?.lastName} disabled={isSaving} slotProps={{ input: { 'maxLength': 50 } }} />
                                    </FormControl>
                                    <FormControl required>
                                        <FormLabel>Country</FormLabel>
                                        <Autocomplete
                                            placeholder="Choose a country"
                                            required
                                            slotProps={{
                                                input: {
                                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                                },
                                            }}
                                            options={countries}
                                            autoHighlight
                                            getOptionLabel={(option) => option.name}
                                            getOptionKey={(option) => option.name}
                                            value={selectedCountry}
                                            onChange={(event, newValue) => {
                                                setCountry(newValue?.name);
                                            }}
                                            renderOption={(props, option) => (
                                                <AutocompleteOption {...props}>
                                                    <ListItemDecorator>
                                                        <img
                                                            loading="lazy"
                                                            width="20"
                                                            srcSet={`https://flagcdn.com/w40/${option.iso2.toLowerCase()}.png 2x`}
                                                            src={`https://flagcdn.com/w20/${option.iso2.toLowerCase()}.png`}
                                                            alt=""
                                                        />
                                                    </ListItemDecorator>
                                                    <ListItemContent sx={{ fontSize: 'sm' }}>
                                                        {option.name}
                                                    </ListItemContent>
                                                </AutocompleteOption>
                                            )}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Your role</FormLabel>
                                        <ButtonGroup spacing='0.5rem'>
                                            {
                                                roles.map((r) => {
                                                    return (
                                                        <Button
                                                            variant={r === role ? 'solid' : 'soft'}
                                                            color='primary'
                                                            key={r}
                                                            onClick={() => {
                                                                setRole(r)
                                                            }}>{r}</Button>
                                                    )
                                                })
                                            }
                                        </ButtonGroup>
                                        {
                                            role === "Other"
                                                ? <Input type="text" 
                                                name="roleDesc" 
                                                disabled={isSaving} 
                                                slotProps={{ input: { 'maxLength': 50 } }} 
                                                sx={{
                                                    my: 1
                                                }} />
                                                : <></>
                                        }
                                    </FormControl>
                                    <Stack gap={4} sx={{ mt: 2 }}>
                                        <Button type="submit" fullWidth disabled={isSaving}>
                                            {isSaving ? 'Please wait...' : 'Continue'}
                                        </Button>
                                    </Stack>
                                </form>
                            </Stack>
                        </Card>
                    </Box>
                    <Box component="footer" sx={{ py: 3 }}>
                        <Typography level="body-xs" textAlign="center">
                            Pavuk AI © {new Date().getFullYear()} | A SemanticForce Company
                        </Typography>
                    </Box>
                </Box>
                <AuthContext.Consumer>
                    {
                        ctx =>
                            <>
                                <MixpanelInit user={ctx.user} debug={true} />
                            </>
                    }
                </AuthContext.Consumer>
            </Box>
        </RequireAuth>
    </>)
}
