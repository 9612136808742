import * as React from 'react';
import GlobalStyles from '@mui/joy/GlobalStyles';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Checkbox from '@mui/joy/Checkbox';
import FormControl from '@mui/joy/FormControl';
import FormLabel, { formLabelClasses } from '@mui/joy/FormLabel';
import Link from '@mui/joy/Link';
import Input from '@mui/joy/Input';
import Typography from '@mui/joy/Typography';
import Stack from '@mui/joy/Stack';
import { PavukLogoIcon } from 'components/icons/PavukLogoIcon';
import ColorSchemeToggle from 'components/ColorSchemeToggle/ColorSchemeToggle';
import { setAuthToken, useAuth } from 'context/Auth';
import { useNavigate } from 'react-router';

import { useMutation } from 'react-query';
import { AuthOptions, createUser } from 'api/users';

interface FormElements extends HTMLFormControlsCollection {
    email: HTMLInputElement;
    password: HTMLInputElement;
    confirm: HTMLInputElement;
}

interface SignUpFormElement extends HTMLFormElement {
    readonly elements: FormElements;
}

export default function SignUpPage() {
    const auth = useAuth()
    const navigate = useNavigate()

    const { isLoading: isSaving, mutateAsync: signUpUser } = useMutation(createUser, {
        onSuccess: (data) => {
            setAuthToken(data.token)
            window.location.href = '/profile'
        }
    })

    React.useEffect(() => {
        if (!!(auth.user)) {
            if(!auth.user.profile.lastName) {
                navigate("/profile")
            }
            else{
                navigate('/apps')
            }
        }
    })

    return (
        <><GlobalStyles
            styles={{
                ':root': {
                    '--Collapsed-breakpoint': '769px', // form will stretch when viewport is below `769px`
                    '--Cover-width': '50vw', // must be `vw` only
                    '--Form-maxWidth': '800px',
                    '--Transition-duration': '0.4s', // set to `none` to disable transition
                },
            }}
        />
            <Box
                sx={(theme) => ({
                    width:
                        'clamp(100vw - var(--Cover-width), (var(--Collapsed-breakpoint) - 100vw) * 999, 100vw)',
                    transition: 'width var(--Transition-duration)',
                    transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
                    position: 'relative',
                    zIndex: 1,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    backdropFilter: 'blur(12px)',
                    backgroundColor: 'rgba(255 255 255 / 0.2)',
                    [theme.getColorSchemeSelector('dark')]: {
                        backgroundColor: 'rgba(19 19 24 / 0.4)',
                    },
                })}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: '100dvh',
                        width:
                            'clamp(var(--Form-maxWidth), (var(--Collapsed-breakpoint) - 100vw) * 999, 100%)',
                        maxWidth: '100%',
                        px: 2,
                    }}
                >
                    <Box
                        component="header"
                        sx={{
                            py: 3,
                            display: 'flex',
                            alignItems: 'left',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box
                            sx={{
                                gap: 2,
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <PavukLogoIcon />
                            <Typography level="title-lg">Pavuk AI</Typography>
                        </Box>
                        <ColorSchemeToggle onModeChange={() => {
                            return
                        }} />
                    </Box>
                    <Box
                        component="main"
                        sx={{
                            my: 'auto',
                            py: 2,
                            pb: 5,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                            width: 400,
                            maxWidth: '100%',
                            mx: 'auto',
                            borderRadius: 'sm',
                            '& form': {
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                            },
                            [`& .${formLabelClasses.asterisk}`]: {
                                visibility: 'hidden',
                            },
                        }}
                    >
                        <Stack gap={4} sx={{ mb: 2 }}>
                            <Stack gap={1}>
                                <Typography level="h3">Sign up</Typography>
                                <Typography level="body-sm">
                                    Already have account?{' '}
                                    <Link href="/login" level="title-sm">
                                        Sign in
                                    </Link>
                                </Typography>
                            </Stack>
                        </Stack>
                        <Stack gap={4} sx={{ mt: 2 }}>
                            <form
                                onSubmit={(event: React.FormEvent<SignUpFormElement>) => {
                                    event.preventDefault();
                                    const formElements = event.currentTarget.elements;

                                    const data: AuthOptions = {
                                        email: formElements.email.value,
                                        password: formElements.password.value
                                    };

                                    signUpUser(data)
                                }}
                            >
                                <FormControl required>
                                    <FormLabel>Email</FormLabel>
                                    <Input type="email" name="email" disabled={isSaving} slotProps={{ input: { 'maxLength': 50 } }} />
                                </FormControl>
                                <FormControl required>
                                    <FormLabel>Password</FormLabel>
                                    <Input type="password" name="password" disabled={isSaving} slotProps={{ input: { 'maxLength': 50 } }} />
                                </FormControl>
                                <Stack gap={4} sx={{ mt: 2 }}>
                                    <Box
                                    >
                                        <Checkbox disabled={isSaving} size="sm" label={<span>I accept&nbsp;
                                            <Link level="title-sm" href="#replace-with-a-link">
                                                Terms of service
                                            </Link></span>} name="confirm" required />
                                    </Box>
                                    <Button type="submit" fullWidth disabled={isSaving}>
                                        {isSaving ? 'Please wait...' : 'Sign up'}
                                    </Button>
                                </Stack>
                            </form>
                        </Stack>
                    </Box>
                    <Box component="footer" sx={{ py: 3 }}>
                        <Typography level="body-xs" textAlign="center">
                            Pavuk AI © {new Date().getFullYear()} | A SemanticForce Company
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={(theme) => ({
                    height: '100%',
                    position: 'fixed',
                    right: 0,
                    top: 0,
                    bottom: 0,
                    left: 'clamp(0px, (100vw - var(--Collapsed-breakpoint)) * 999, 100vw - var(--Cover-width))',
                    transition:
                        'background-image var(--Transition-duration), left var(--Transition-duration) !important',
                    transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
                    backgroundColor: 'background.level1',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundImage:
                        'url(https://images.unsplash.com/photo-1527181152855-fc03fc7949c8?auto=format&w=1000&dpr=2)',
                    [theme.getColorSchemeSelector('dark')]: {
                        backgroundImage:
                            'url(https://images.unsplash.com/photo-1572072393749-3ca9c8ea0831?auto=format&w=1000&dpr=2)',
                    },
                })}
            />
        </>
    );
}
