import React, { ReactNode, useMemo, useState } from "react";

import Box from '@mui/joy/Box';
import Link from '@mui/joy/Link';
import Typography from '@mui/joy/Typography';

import Sheet from '@mui/joy/Sheet';
import Input from "@mui/joy/Input";

import { Link as RouterLink } from 'react-router-dom';

// icons
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { Flow, useFlows } from "api/flows";
import Card from "@mui/joy/Card";
import CardOverflow from "@mui/joy/CardOverflow";
import AvatarGroup from "@mui/joy/AvatarGroup";
import Avatar from "@mui/joy/Avatar";
import Skeleton from "@mui/joy/Skeleton";
import _ from "lodash";
import { trackAppSelect } from "api/tracking";
import { AuthContext } from "context/Auth";
import { Subscription, useSubscription } from "api/billing";
import InfoIcon from "assets/images/InfoIcon";


const FlowCard = ({ flow, subscription }: { flow: Flow | null, subscription: Subscription }) => {
    const isBeta = JSON.parse(flow?.inputModel).isBeta
    
    return (
        <AuthContext.Consumer>
            {
                ctx =>
                    <><Card
                        variant="outlined"
                        sx={{
                            '--Card-radius': (theme) => theme.vars.radius.sm,
                            boxShadow: 'none',
                            p: 3
                        }}
                    >
                        <CardOverflow
                        >
                            <Box sx={{
                                mt: 2
                            }}>
                                {
                                    flow ?
                                        <AvatarGroup
                                            size="sm"
                                            sx={{ '--AvatarGroup-gap': '-8px', '--Avatar-size': '40px' }}
                                        >
                                            <Avatar
                                                src={flow.logo}
                                            />
                                        </AvatarGroup>
                                        : <>
                                            <Skeleton variant="circular" width={40} height={40} />
                                        </>
                                }
                            </Box>
                        </CardOverflow>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ flex: 1 }}>
                                {
                                    flow ?
                                        <>
                                            <Link component={RouterLink} to={`/apps/setup/${flow.id}`} onClick={() => {
                                                if (ctx.user) {
                                                    trackAppSelect(ctx.user, subscription, flow)
                                                }
                                            }} overlay>
                                                <Typography>{flow.title}</Typography>
                                                
                                            </Link>
                                            <Typography level="body-xs" mt={0.5}>
                                                {flow.shortDescription}
                                            </Typography>
                                            {isBeta ? <Typography className="beta-info" sx={(theme)=>({
                                                border:"1px solid #E9EEF1",
                                                borderRadius:"12px",
                                                padding:"4px 12px",
                                                width:"fit-content",
                                                display:"flex",
                                                gap:"4px",
                                                fontSize:"14px",
                                                alignItems:"center",
                                                lineHeight:"14px",
                                                color:"#70828D",
                                                marginTop:"8px",
                                                background:"#F0F5F9",
                                                [theme.getColorSchemeSelector('dark')]: {
                                                    background:"#181A1C",
                                                    border:"1px solid #32383E",
                                                    color:"#CDD7E1",
                                                  },

                                            })}> <InfoIcon/> Beta</Typography> : null}
                                        </>
                                        :
                                        <>
                                            <Skeleton variant="rectangular" width={100} height={24} />
                                            <Skeleton variant="rectangular" width={180} height={18} />
                                        </>
                                }
                            </Box>
                        </Box>
                    </Card></>
            }
        </AuthContext.Consumer>)
}

const FlowsList = ({ flows, subscription }: { flows: Flow[] | null | undefined, subscription: Subscription }) => {
    const [search, setSearch] = useState('')

    const filtered = useMemo(() => {
        return !search || search.length < 1 ? flows : _.filter(flows, (flow: Flow) => flow.title.toLowerCase().indexOf(search.toLowerCase()) > -1)
    }, [search, flows])

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    my: 1,
                    gap: 1,
                    flexDirection: { xs: 'column', sm: 'row' },
                    alignItems: { xs: 'start', sm: 'center' },
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                }}
            >
                <Typography level="h2">Apps</Typography>
                <Input
                    size="sm"
                    variant="outlined"
                    placeholder="Search apps..."
                    startDecorator={<SearchRoundedIcon color="primary" />}
                    value={search}
                    onChange={(event) => setSearch(event.target.value)}
                    sx={{
                        flexBasis: '500px',
                        display: {
                            xs: 'none',
                            sm: 'flex',
                        },
                        boxShadow: 'sm',
                    }}
                />
            </Box>
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fit, minmax(240px, 1fr))',
                    gap: 2,
                }}
            >
                <Sheet
                    variant="plain"
                    sx={{ borderRadius: 'sm', gridColumn: '1/-1' }}
                >
                </Sheet>
                {
                    flows && filtered && filtered.map((flow) => {
                        return (<FlowCard flow={flow} key={flow.id} subscription={subscription} />)
                    })
                }
            </Box>
        </>
    )
}

export const FlowsPage = () => {
    const { data: flows, isLoading } = useFlows()
    const { data: subscription, isLoading: subscriptionIsLoading } = useSubscription()

    if (isLoading || subscriptionIsLoading) {
        return (<></>)
    }

    if (flows && subscription)
        return (<FlowsList flows={flows} subscription={subscription} />)

    return <></>
}