import { CreditCardOutlined, StarOutlined, SupportAgent } from "@mui/icons-material";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import Chip from "@mui/joy/Chip";
import FormControl from "@mui/joy/FormControl";
import FormHelperText from "@mui/joy/FormHelperText";
import Grid from "@mui/joy/Grid";
import LinearProgress from "@mui/joy/LinearProgress";
import Radio from "@mui/joy/Radio";
import RadioGroup from "@mui/joy/RadioGroup";
import Sheet from "@mui/joy/Sheet";
import Stack from "@mui/joy/Stack";
import Tab, { tabClasses } from "@mui/joy/Tab";
import TabList from "@mui/joy/TabList";
import TabPanel from "@mui/joy/TabPanel";
import Table from "@mui/joy/Table";
import Tabs from "@mui/joy/Tabs";
import Typography from "@mui/joy/Typography"
import { Product, Subscription, useProducts, useSubscription, useUsage } from "api/billing";
import { trackAccountUpgradeStart } from "api/tracking";
import { User, useUser } from "api/users";
import dayjs from "dayjs";
import _ from "lodash";
import React, { ReactNode, useMemo, useState } from "react";
import { BillingPlansGrid } from "./BillingPlansGrid";
import { prettifyNumber } from "utils/formattingUtils";

const CurrentPlanPanel = ({ user, subscription, products }: { user: User, subscription: Subscription, products: Product[] }) => {
    const w: any = window
    const [upgrade, setUpgrade] = useState(false)

    const { isLoading, data: usage } = useUsage()

    if (isLoading || !usage)
        return (<></>)

    const recordsLimit = (usage.product.type === 'package' ? subscription.records : Math.max(usage.product.limits.records, subscription.records ?? 0)) ?? 500
  const exportsLimit = (usage.product.type === 'package' ? subscription.exports : Math.max(usage.product.limits.exports, subscription.exports ?? 0)) ?? 10

    const records = Math.min(100, Math.ceil(usage.usage.records / recordsLimit * 100))
    const exports = Math.min(100, Math.ceil(usage.usage.exports / exportsLimit * 100))

    const closeToLimit = exports >= 80 || records >= 80

    const openSupportChat = () => {
        const tc: any = w.tidioChatApi
        if (tc) {
            tc.display(true)
            tc.open()
        }
    }

    return (
        <Box
            sx={{
                my: 'auto',
                py: 2,
                pb: 5,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                width: 1000,
                maxWidth: '100%',
                mx: 'auto',
                borderRadius: 'sm',
                '& form': {
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                }
            }}
        >
            <Card>
                <Stack gap={4} sx={{ mb: 2 }}>
                    <Stack gap={1}>
                        <Typography level="h3" startDecorator={<CreditCardOutlined />}>Manage your billing</Typography>
                    </Stack>
                    {
                        upgrade ?
                            <BillingPlansGrid
                                user={user}
                                subscription={subscription}
                                products={products} />
                            :
                            <>
                                <Stack gap={2}>
                                    <Stack gap={1}>
                                        <Typography level="body-md">Your current plan</Typography>
                                        <Typography level="title-md">{subscription.product.name}</Typography>
                                    </Stack>
                                    <Stack gap={1}>
                                        <Typography level="body-md">Current billing period</Typography>
                                        <Typography level="title-md">{dayjs.unix(subscription.start).format('DD MMMM YYYY')} - {dayjs.unix(subscription.until).format('DD MMMM YYYY')}</Typography>
                                    </Stack>
                                    <Box>
                                        <Typography level="title-md">Current usage</Typography>
                                        <Stack
                                            direction="row"
                                            justifyContent="space-between"
                                            spacing={2}
                                            sx={{
                                                mt: 2
                                            }}>
                                            <Typography level='title-md'>Records</Typography>
                                            <Typography level='title-sm'>{prettifyNumber(usage.usage.records, false)} / {recordsLimit === 1000000000 ? 'Unlimited' : prettifyNumber(recordsLimit, false)}</Typography>
                                        </Stack>
                                        <LinearProgress variant="outlined" value={records} determinate sx={{ my: 1 }} />
                                        <Stack
                                            direction="row"
                                            justifyContent="space-between"
                                            spacing={2}>
                                            <Typography level='title-md'>Exports</Typography>
                                            <Typography level='title-sm'>{prettifyNumber(usage.usage.exports, false)} / {exportsLimit > 0 ? prettifyNumber(exportsLimit, false) : 'Unlimited'}</Typography>
                                        </Stack>
                                        <LinearProgress variant="outlined" value={exports} determinate sx={{ my: 1 }} />
                                    </Box>
                                </Stack>
                                <Box sx={{
                                    display: 'flex',
                                    flex: 1,
                                    gap: 2,
                                    justifyContent: 'center'
                                }}>
                                    <Button
                                        color="primary"
                                        variant="outlined"
                                        startDecorator={<SupportAgent />}
                                        onClick={openSupportChat}
                                    >
                                        Contact Sales
                                    </Button>
                                    <Button
                                        startDecorator={<StarOutlined />}
                                        color="primary"
                                        onClick={
                                            () => {
                                                setUpgrade(true)
                                            }
                                        }
                                    >
                                        Upgrade Plan
                                    </Button>
                                </Box></>
                    }
                </Stack>
            </Card>
        </Box>)
}

const UpgradePlanPanel = ({ user, subscription, products }: { user: User, subscription: Subscription, products: Product[] }) => {

    return (
        <Box
            sx={{
                my: 'auto',
                py: 2,
                pb: 5,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                width: 1000,
                maxWidth: '100%',
                mx: 'auto',
                borderRadius: 'sm',
                '& form': {
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                }
            }}
        >
            <Card>
                <Stack gap={4} sx={{ mb: 2 }}>
                    <Stack gap={1}>
                        <Typography level="h3" startDecorator={<StarOutlined />}>Upgrade your experience</Typography>
                    </Stack>
                </Stack>
                <BillingPlansGrid
                    user={user}
                    subscription={subscription}
                    products={products} />
            </Card>
        </Box>)
}

export const BillingPage = () => {
    const { isLoading, data: subscription } = useSubscription()
    const { isLoading: isUserLoading, data: user } = useUser()
    const { isLoading: productsLoading, data: products } = useProducts()

    if (isLoading || productsLoading)
        return (<></>)

    if (subscription?.product?.productId == 'free' && products && user) {
        return <UpgradePlanPanel
            user={user}
            subscription={subscription}
            products={products} />
    }

    if (subscription && products && user) {
        return <CurrentPlanPanel
            user={user}
            subscription={subscription}
            products={products} />
    }

    return (<></>)
}