import React from 'react'

import Box from '@mui/joy/Box'
import Sheet from '@mui/joy/Sheet'

export const Preloader = () => {
    return (
        <Box id="preloader" sx={{
            position: 'fixed',
            width: '100vw',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            zIndex: 999999,
            borderRadius: '0'
        }}>
            <Sheet className="preloader-wrap" sx={{
                backgroundColor: '#000000'
            }}>
                <img src="/favicon.ico" alt="logo" className="img-fluid preloader-icon" />
                <div className="loading-bar"></div>
            </Sheet>
        </Box>)
}