export const transformCrawlUrl = (
    crawlUrl: string,
    regexForCutLink: any,
    regexForCutID: any,
    linkToAdd: string
) => {
    if (regexForCutLink === undefined || regexForCutID === undefined) {
        return crawlUrl
    }

    const match = crawlUrl.match(new RegExp(regexForCutLink))

    if (match !== null) {
        let linkUrl = match[0]
        let videoID = linkUrl.replace(new RegExp(regexForCutID), "")
        return linkToAdd + videoID
    } else {
        return crawlUrl
    }
}