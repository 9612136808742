import * as React from 'react';
import { CssVarsProvider, useColorScheme } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';

import { PaletteMode } from "@mui/material";
import {
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  experimental_extendTheme as extendMaterialTheme,
  THEME_ID,
  useTheme, ThemeProvider, createTheme
} from "@mui/material/styles";

import useScript from './utils/useScript';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AppRoutes } from 'AppRoutes';
import { Toaster } from 'react-hot-toast';
import { AxiosDefaults } from 'AxiosDefaults';
import { AuthContext, AuthProvider, CheckAuth } from 'context/Auth';

import LogRocket from 'logrocket';
import { MixpanelInit } from 'components/Mixpanel/MixpanelInit';
import { LogRocketInit } from 'components/Logrocket/LogrocketInit';
import { IntercomSettings } from 'components/Intercom/IntercomSettings';
LogRocket.init('y7fo3e/pavuk-ai');

const useEnhancedEffect =
  typeof window !== 'undefined' ? React.useLayoutEffect : React.useEffect;


const queryClient = new QueryClient();

export default function App() {
  const status = useScript(`https://unpkg.com/feather-icons`)
  const joyMode: any = localStorage.getItem('joy-mode');
  const modeCacheValue = joyMode && joyMode === 'light' ? 'light' : 'dark';
  const [mode, setMode] = React.useState<PaletteMode>(modeCacheValue)

  const darkTheme = createTheme({
    palette: {
      mode: mode === 'dark' ? 'dark' : 'light'
    },
  });

  const materialTheme = extendMaterialTheme(darkTheme);

  useEnhancedEffect(() => {
    // Feather icon setup: https://github.com/feathericons/feather#4-replace
    // @ts-ignore
    if (typeof feather !== 'undefined') {
      // @ts-ignore
      feather.replace();
    }
  }, [status]);

  React.useEffect(() => {
    const html = document.querySelector('html')
    html?.setAttribute('data-mui-color-scheme', mode)
  }, [mode])

  return (
    <QueryClientProvider client={queryClient}>
      <MaterialCssVarsProvider theme={{ [THEME_ID]: materialTheme }} defaultMode='dark'>
        <CssVarsProvider disableTransitionOnChange defaultMode='dark'>
          <CssBaseline />
          <Toaster position="top-center" reverseOrder={false} />
          <CheckAuth />
          <AxiosDefaults />
          <AuthProvider>
            <AppRoutes onModeChange={(newMode: string) => {
              setMode(newMode === 'dark' ? 'dark' : 'light')
            }} />
            <AuthContext.Consumer>
              {
                ctx =>
                  <>
                    <MixpanelInit user={ctx.user} debug={true} />
                    <IntercomSettings user={ctx.user} />
                  </>
              }
            </AuthContext.Consumer>
          </AuthProvider>
        </CssVarsProvider>
      </MaterialCssVarsProvider>
    </QueryClientProvider>
  );
}