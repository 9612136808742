import axios from 'axios'
import { UseQueryOptions, useQuery } from 'react-query'
import { ApiError, accountsApi } from './common'

export interface Profile {
    name: string
    lastName: string
    data: { [index: string]: string | number | boolean | object | null | undefined }
}

export interface User {
    userId: string
    email: string
    profile: Profile
    created: number
}

export interface UserAuth extends User {
    token: string
    isNew: boolean
}

export interface AuthOptions {    
    email: string
    password: string
}

export interface PasswordChangeOptions {
    email: string
}

export interface PasswordSaveOptions {
    email: string
    code: string
    password: string
}

export const getUserModel = async (): Promise<User> => {
    const { data } = await axios.get<User>(`${accountsApi}/v1/users/me`)
    return data
}

export const useUser = (options?: UseQueryOptions<User, ApiError>) => {
    return useQuery<User, ApiError>('user', getUserModel, options)
}

export const authorizeUser = async (options:AuthOptions): Promise<UserAuth> => {    
    const { data } = await axios.post<UserAuth>(`${accountsApi}/v1/users/login`, options)
    return data
}

export const createUser = async (options:AuthOptions): Promise<UserAuth> => {    
    const { data } = await axios.post<UserAuth>(`${accountsApi}/v1/users`, options)
    return data
}

export const updateProfile = async (profile: Profile) : Promise<User> => {
    const { data } = await axios.post<User>(`${accountsApi}/v1/users/me/profile`, profile)
    return data
}

export const startPasswordChange = async (options: PasswordChangeOptions) : Promise<any> => {
    const { data } = await axios.post(`${accountsApi}/v1/users/password/code`, options)
    return data
}

export const changePassword = async (options: PasswordSaveOptions) : Promise<any> => {
    const { data } = await axios.post(`${accountsApi}/v1/users/password/change`, options)
    return data
}