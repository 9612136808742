import { Check, InfoOutlined, KeyboardArrowRight, RefreshOutlined } from "@mui/icons-material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Avatar from "@mui/joy/Avatar";
import AvatarGroup from "@mui/joy/AvatarGroup";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import FormControl from "@mui/joy/FormControl";
import FormHelperText from "@mui/joy/FormHelperText";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input"
import TextField from "@mui/material/TextField"
import LinearProgress from "@mui/joy/LinearProgress";
import Link from "@mui/joy/Link";
import Stack from "@mui/joy/Stack";
import Step from "@mui/joy/Step";
import StepButton from "@mui/joy/StepButton";
import StepIndicator from "@mui/joy/StepIndicator";
import Stepper from "@mui/joy/Stepper";
import Typography from "@mui/joy/Typography";
import { Flow, FlowProgressUpdate, FlowSession, runFlow, useFlow, useFlowSession, useFlowSocketConnection } from "api/flows";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import _ from "lodash";
import { RedirectToPage } from "pages/Security/RedirectTo";
import ErrorHandler from "../../components/ErrorHandler";
import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { saveDataToExcel } from "utils/excelUtils";
import StarOutlinedIcon from '@mui/icons-material/StarOutlined';
import { User, useUser } from "api/users";
import { Subscription, useFreeProduct, useSubscription, useUsage } from "api/billing";
import { trackAppError, trackAppStart, trackAppSuccess, trackDownload, trackOpenBillingPage } from "api/tracking";

import { useLottie } from "lottie-react"
import pavukAnimation from '../../components/LottieFiles/pavuk.json'
import { transformCrawlUrl } from "utils/helpers";
import { calculateBillingStats } from "utils/billing";
import InfoIcon from "assets/images/InfoIcon";


export const FlowSetupStepper = ({ flow, activeStep, filledStep }: { flow: Flow, activeStep: number, filledStep: number }) => {
    // const [checkedSteps, setCheckedSteps] = useState<any>({
    //     '1': false,
    //     '2': false,
    //     '3': false,
    // })
    const steps = [{ id: 'setup-step', name: flow.metadata.stages[0]?.label ?? 'Setup' }, { id: 'export-step', name: 'Pavuk AI ' + (flow.metadata.stages[1]?.label ?? 'prepares data') }, { id: 'result-step', name: 'Download result' }]
    // useEffect(() => {
    //     let checkedStepsCopy = { ...checkedSteps };

    //     for (let key in checkedStepsCopy) {
    //         if (Number(key) <= filledStep) {
    //             checkedStepsCopy[key] = true
    //         } else {
    //             checkedStepsCopy[key] = false
    //         }
    //     }

    //     setCheckedSteps(checkedStepsCopy)
    // }, [filledStep])

    return (
        <Box sx={{
            px: 2,
            mb: 2
        }}>
            <Stepper sx={{ width: '100%' }}>
                {steps.map((step, index) => (
                    <Step
                        key={step.id}
                        indicator={
                            <StepIndicator
                                variant={activeStep < index ? 'soft' : 'solid'}
                                color={activeStep < index ? 'neutral' : 'primary'}
                            >
                                {activeStep < index ? index + 1 : <Check />}
                            </StepIndicator>
                        }
                        sx={{
                            '&::after': {
                                ...(activeStep > index &&
                                    index !== 2 && { bgcolor: 'primary.solidBg' }),
                            },
                        }}
                    >
                        <StepButton>{step.name}</StepButton>
                    </Step>
                ))}
            </Stepper>
        </Box>
    )
}

const FlowExportResult = ({
    sessionId,
    data,
    fileName
}: {
    sessionId: string,
    data: string,
    fileName: string
}) => {
    const navigate = useNavigate()
    const { isLoading, data: subscription } = useSubscription()
    const { isLoading: loadingSession, data: session } = useFlowSession(sessionId)
    const { isLoading: loadingUser, data: user } = useUser()

    if (isLoading || loadingSession || loadingUser)
        return (<></>)

    if (!subscription || !session || !user)
        return (<></>)

    const isFree = subscription.product.productId == 'free'

    return (<Box sx={{
        textAlign: 'center',
        alignItems: 'center'
    }}>
        <Typography level="h3">Your data is ready for download</Typography><Typography level="body-md" sx={{
            paddingTop: 2
        }}>You also can find it in <Link href="/downloads"> downloads </Link> to download later</Typography>
        {
            isFree
                ? <>
                    <Typography level="body-md" sx={{
                        paddingTop: 2
                    }}>During trial your account limited to 500 records and 3 exports total</Typography>
                    <Typography level="body-md">Upgrade now to unlock full Pavuk AI power for data export</Typography></>
                : <></>
        }
        <Box sx={{
            display: 'flex',
            flex: 1,
            gap: 2,
            justifyContent: 'center',
            marginTop: 2
        }}>
            <Button
                color="primary"
                variant={isFree ? "outlined" : "solid"}
                onClick={
                    () => {
                        trackDownload(user, subscription, session, session.count, 'result')
                        return saveDataToExcel(data, fileName)
                    }
                }
            >
                Download
            </Button>
            {
                subscription.product.productId == 'free'
                    ?
                    <Button
                        startDecorator={<StarOutlinedIcon />}
                        color="primary"
                        onClick={
                            () => {
                                trackOpenBillingPage(user, subscription, 'result')
                                navigate('/billing')
                            }
                        }
                    >
                        Upgrade Plan
                    </Button>
                    : <></>
            }
        </Box>
    </Box>)
}

const SessionProgressInfo = ({
    sessionId
}: { sessionId: string }) => {
    const { data: session, isLoading } = useFlowSession(sessionId, {
        refetchInterval: 5000
    })

    if (!session)
        return (<></>)

    return (
        <Box sx={{
            my: 1
        }}>
            <h3  className="line-clamp-1">{session.title}</h3>
            <Typography level="title-md">{session.count} records ready</Typography>
        </Box>
    )
}

const FlowExportProgress = ({
    sessionId,
    room,
    flow,
    stageNum,
    onProgress
}: {
    sessionId: string,
    room: string,
    flow: Flow,
    stageNum: number,
    onProgress: (data: FlowProgressUpdate, stageIndex: number) => void
}) => {
    
    const syncData = (data: FlowProgressUpdate) => {
        const stages = flow.metadata.stages.map((stage) => stage.callback)

        if (stages && stages.length > 0) {
            const currentStage = stageNum

            console.dir(data)

            const cStage = data.data.stage
            for (let i = currentStage; i < stages.length; i++) {
                if (stages[i] === cStage) {
                    const nStageNum = i
                    if (nStageNum > stageNum) {
                        onProgress(data, nStageNum)
                    }
                }
            }
        }
        else {
            onProgress(data, stageNum + 1)
        }
    }

    const lottieOptions = {
        animationData: pavukAnimation,
        loop: true,
        autoplay: true
    }

    const { View: PavukAnimationView } = useLottie(lottieOptions, {
        height: 50
    })

    const connection = useFlowSocketConnection(sessionId, room, syncData)
    const stage = flow.metadata.stages[stageNum]
    const progress = Math.floor(((stageNum + 1) * 100) / flow.metadata.stages.length)
    
    return (<Box sx={{
        textAlign: 'center'
    }}>
        <Typography level="h4">Pavuk AI {stage.label} at the moment</Typography>
        <SessionProgressInfo sessionId={sessionId} />
        <Box sx={{
            width: '80%',
            margin: 'auto'
        }}>
            <Box
                sx={{
                    width: '100%'
                }}
            >
                {PavukAnimationView}
            </Box>
            <LinearProgress variant="outlined" size="lg" />
        </Box>
        <Typography level="body-md" sx={{
            marginTop: 3
        }}>You can wait until export complete or use link we will send to email when data ready</Typography>
        <Typography level="body-md" sx={{
            marginBottom: 3
        }}>Also you can find all exports in <Link href="/downloads">Downloads</Link></Typography>
    </Box>)
}

const FlowSettings = ({
    flow,
    user,
    subscription,
    link,
    room,
    sessionId,
    step,
    file,
    content
}: {
    flow: Flow,
    user: User,
    subscription: Subscription,
    link: string | null,
    room: string | null,
    sessionId: string | null,
    step: number,
    file: string | null,
    content: string | null
}) => {
    const queryClient = useQueryClient()
    const [flowLink, setFlowLink] = useState(link ?? '')
    const { isLoading, data: usage } = useUsage()
    const [recordsTotal, setRecordsTotalLimit] = useState<number | null>(1000)
    const [recordsLimit, setRecordsLimit] = useState<number | null>(recordsTotal)
    const [flowRoom, setFlowRoom] = useState(room)
    const [flowStep, setFlowStep] = useState(step)
    const [flowFilledStep, setFlowFilledStep] = useState(step - 1)
    const [flowSessionId, setSessionId] = useState(sessionId)
    const [fileName, setFileName] = useState<string | null>(file)
    const [fileContent, setFileContent] = useState<string | null>(content)

    const [datePickerFocus, setDatePickerFocus] = useState(false)
    const [dateFrom, setFrom] = useState(null)
    const [documentAgeLimit, setDocumentAgeLimit] = useState<number | null>(null)
    const dateFormat = 'MMM DD, YYYY'

    const [error, setError] = useState<string | null>(null)
    const [errorLink, setErrorLink] = useState<string | null>(null)

    const [flowStage, setFlowStage] = useState(1)

    const navigate = useNavigate()

    const { mutateAsync: startFlow, isLoading: inProgress } = useMutation(runFlow)
    const regexForCutLink = JSON.parse(flow.inputModel).regexForCutLink
    const regexForCutID = JSON.parse(flow.inputModel).regexForCutID
    const linkToAdd = JSON.parse(flow.inputModel).shortLink

   

    const isValid = useMemo(() => {
        if (!flowLink)
            return false

        if (!(flow?.metadata?.model?.regexRule))
            return true

        const regexExpr = new RegExp(flow?.metadata?.model?.regexRule)

        return regexExpr.test(flowLink)
    }, [flowLink])

    useEffect(() => {
        if (usage) {
            const totalAvailable = ((usage.product.type == 'package' ? subscription.records : usage.product.limits.records) ?? 1000) - (usage.usage.records ?? 0);
            setRecordsTotalLimit(totalAvailable)
            setRecordsLimit(totalAvailable)
        }
    }, [usage])

    let inputs = flow.metadata?.inputs ?? []
    inputs = _.filter(inputs, (input) => input.type !== 'Email')
    const linkInput = inputs.length > 0 ? inputs[0] : {
        type: "Link",
        description: "Page link"
    }
    const isBeta = JSON.parse(flow?.inputModel).isBeta

    const linkPlaceholder = flow?.metadata?.model?.exampleInfo?.exampleUrlPlaceholder
        ? 'Example: ' + flow?.metadata?.model?.exampleInfo?.exampleUrlPlaceholder
        : ''

    const title = flowStep === 0 ? flow.title : (flowStep === 1 ? `Your ${flow.title} App Running...` : `App ${flow.title} Completed`)

    const handleChangeCustomLimit = (event: any) => {
        const value = Number(event.target.value) ? Number(event.target.value) : 1
        setRecordsLimit(recordsTotal && value > recordsTotal ? recordsTotal : value)
    }

    return (
        <Box
            sx={{
                my: 'auto',
                py: 2,
                pb: 5,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                width: 900,
                maxWidth: '100%',
                mx: 'auto',
                borderRadius: 'sm',
                '& form': {
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                }
            }}
        >
            <Card>
                <Stack gap={4} sx={{ mb: 2 }}>
                    <Stack gap={1}>
                        <Typography level="h3" startDecorator={
                            <AvatarGroup
                                size="sm"
                                sx={{ '--AvatarGroup-gap': '-8px', '--Avatar-size': '40px' }}
                            >
                                <Avatar
                                    src={flow.logo}
                                />
                            </AvatarGroup>
                        }>{title}</Typography>
                        {isBeta ? <Typography className="beta-info" sx={(theme)=>({
                                                border:"1px solid #E9EEF1",
                                                borderRadius:"12px",
                                                padding:"4px 12px",
                                                width:"fit-content",
                                                display:"flex",
                                                gap:"4px",
                                                fontSize:"14px",
                                                alignItems:"center",
                                                lineHeight:"14px",
                                                color:"#70828D",
                                                margin:"8px 0",
                                                background:"#F0F5F9",
                                                [theme.getColorSchemeSelector('dark')]: {
                                                    background:"#181A1C",
                                                    border:"1px solid #32383E",
                                                    color:"#CDD7E1",
                                                  },

                                            })}> <InfoIcon/> Beta</Typography> : null}
                        <Typography level="body-sm">
                            {flow.shortDescription}
                        </Typography>
                    </Stack>
                </Stack>
                {
                    error ?
                        <>
                            <Box>
                                <Typography
                                    variant="soft"
                                    color="danger"
                                    startDecorator="🚨"
                                    fontSize="body-md"
                                    sx={{ '--Typography-gap': '0.5rem', p: 1 }}
                                >
                                    {error}
                                </Typography>

                                <Box sx={{
                                    display: 'flex',
                                    flex: 1,
                                    gap: 2,
                                    justifyContent: 'center',
                                    marginTop: 2
                                }}>
                                    {
                                        errorLink === '/billing'
                                            ?
                                            <Button
                                                startDecorator={<StarOutlinedIcon />}
                                                color="primary"
                                                onClick={
                                                    () => {
                                                        trackOpenBillingPage(user, subscription, 'result')
                                                        navigate('/billing')
                                                    }
                                                }
                                            >
                                                Upgrade Plan
                                            </Button>
                                            : <Button
                                                startDecorator={<RefreshOutlined />}
                                                color="primary"
                                                onClick={
                                                    () => {
                                                        navigate(`/apps/setup/${flow.id}?link=${link}`)
                                                    }
                                                }
                                            >
                                                Retry Export
                                            </Button>
                                    }
                                </Box>
                            </Box>
                        </>
                        :
                        <>
                            <Box>
                                <FlowSetupStepper flow={flow} activeStep={flowStep} filledStep={flowFilledStep} />
                            </Box>
                            <Stack gap={3} sx={{ mt: 2 }}>
                                {
                                    flowStep === 0 || !flowSessionId
                                        ? <>
                                            <FormControl required error={!!flowLink && !isValid}>
                                                <FormLabel>
                                                    {linkInput.description}.
                                                    {
                                                        flow.metadata?.model?.exampleInfo?.exampleUrl ?
                                                            <>{" " + flow.metadata?.model?.exampleInfo?.exampleText} <Link
                                                                component='button'
                                                                onClick={(event) => {
                                                                    setFlowLink(flow.metadata?.model?.exampleInfo?.exampleUrl ?? '')
                                                                    setFlowFilledStep(1)
                                                                }}>{flow.metadata?.model?.exampleInfo?.exampleUrlName}</Link></>
                                                            : <></>
                                                    }
                                                </FormLabel>
                                               
                                               
                                                <Input type="text"
                                                    value={flowLink}
                                                    placeholder={linkPlaceholder}
                                                    slotProps={{
                                                        input: {
                                                            autoComplete: 'new-password',// disable autocomplete and autofill
                                                            style: { height: '40px' }
                                                        },
                                                    }}
                                                    onChange={(event) => {
                                                        setFlowLink(event.target.value)
                                                        setFlowFilledStep(event.target.value !== '' ? 1 : 0)
                                                        return
                                                    }
                                                
                                                    } 
                                                    onBlur={() => {
                                                        setFlowLink(transformCrawlUrl(flowLink,regexForCutLink,regexForCutID,linkToAdd).trim())
                                                    }}
                                                    />


                                                <FormHelperText sx={{ minHeight: "24px" }}>
                                                    {
                                                        !!flowLink && !isValid ?
                                                            <><InfoOutlined />
                                                                Incorrect link format. Example: {flow.metadata?.model?.exampleInfo?.exampleUrl}
                                                            </> : null
                                                    }
                                                </FormHelperText>
                                                <FormControl
                                                    sx={{
                                                        mt: 2
                                                    }}>
                                                    {/* <FormLabel required={false}>Collect comments starting from the date below</FormLabel> */}
                                                    {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DatePicker
                                                            closeOnSelect={true}
                                                            label={dateFrom || datePickerFocus ? '' : "All comments"}
                                                            value={dateFrom}
                                                            format={dateFormat}
                                                            sx={{
                                                                width: '250px'
                                                            }}
                                                            slotProps={{
                                                                textField: {
                                                                    size: 'small',
                                                                    InputLabelProps: {
                                                                        shrink: false
                                                                    },
                                                                    onFocus: () => setDatePickerFocus(true),
                                                                    onBlur: () => setDatePickerFocus(false)
                                                                }
                                                            }}
                                                            onChange={(newValue) => {
                                                                setFrom(newValue)
                                                                setDocumentAgeLimit(dayjs().startOf('day').diff(dayjs(newValue), 'days'))
                                                                return
                                                            }}
                                                        />
                                                    </LocalizationProvider> */}
                                                    <FormLabel required={false}>Records Limit</FormLabel>
                                                    <TextField
                                                        id="outlined-number"
                                                        label=""
                                                        type="number"
                                                        InputLabelProps={{
                                                            shrink: false,
                                                        }}
                                                        sx={{
                                                            width: '250px'
                                                        }}
                                                        InputProps={{ inputProps: { min: 1, max: recordsTotal, style: { padding: '9.3px 14px' } } }}
                                                        value={recordsLimit}
                                                        onChange={handleChangeCustomLimit}
                                                    />
                                                </FormControl>
                                            </FormControl>
                                            {
                                                inProgress ?
                                                    <Button loading variant="solid">
                                                        Starting Export...
                                                    </Button>
                                                    :
                                                    <Button
                                                        endDecorator={<KeyboardArrowRight />}
                                                        color="primary"
                                                        disabled={!isValid}
                                                        onClick={
                                                            () => {
                                                                startFlow({
                                                                    flowId: flow.id,
                                                                    flowLink: flowLink,
                                                                    documentAgeLimit: documentAgeLimit,
                                                                    recordsLimit: recordsLimit
                                                                }, {
                                                                    onSuccess: ((data: FlowSession) => {
                                                                        trackAppStart(user, subscription, data)
                                                                        setSessionId(data.sessionId)
                                                                        setFlowRoom(data.room)
                                                                        setFlowStep(1)
                                                                        setFlowFilledStep(2)
                                                                        navigate(`/flows/setup/${flow.id}/${data.sessionId}`)
                                                                    }),
                                                                    onError: (result: any) => {
                                                                        const error = result.response.data.error
                                                                        const link = result.response.data.link

                                                                        setError(error)
                                                                        setErrorLink(link ?? `/apps/setup/${flow.id}?link=${link}`)
                                                                    }
                                                                })
                                                            }
                                                        }
                                                    >
                                                        Start Export
                                                    </Button>
                                            }
                                        </>
                                        : <>
                                            {
                                                flowStep === 1 && flowSessionId && flowRoom ?
                                                    <FlowExportProgress
                                                        sessionId={flowSessionId}
                                                        flow={flow}
                                                        room={flowRoom}
                                                        stageNum={flowStage}
                                                        onProgress={(update, stageIndex) => {
                                                            setFlowStage(stageIndex)

                                                            if (update.data?.data?.data && update.data?.data?.fileName) {
                                                                if (sessionId) {
                                                                    trackAppSuccess(user, subscription, flow, sessionId, flowLink, update.data.data.count)
                                                                }
                                                                queryClient.invalidateQueries({
                                                                    queryKey: 'subscription'
                                                                })
                                                                setFileContent(update.data.data.data)
                                                                setFileName(update.data.data.fileName)
                                                                setFlowStep(2)
                                                                setFlowFilledStep(3)
                                                            }

                                                            if (update.data?.errorMessage && update.data?.stage !== 'finish') {
                                                                if (sessionId) {
                                                                    trackAppError(user, subscription, flow, sessionId, flowLink, update.data.errorMessage)
                                                                }
                                                            }
                                                        }} />
                                                    :
                                                    (flowStep === 2 && fileName && fileContent
                                                        ? <FlowExportResult
                                                            sessionId={flowSessionId}
                                                            fileName={fileName}
                                                            data={fileContent}
                                                        />
                                                        :
                                                        <></>)
                                            }
                                        </>
                                }
                            </Stack>
                        </>
                }
            </Card>
        </Box>)
}

export const FlowProgress = () => {
    const { flowId, sessionId } = useParams()

    const { data: flow, isLoading: loadingFlow } = useFlow(flowId ?? '')
    const { data: session, isLoading: loadingSession } = useFlowSession(sessionId ?? '')
    const { data: user, isLoading: loadingUser } = useUser()
    const { data: subscription, isLoading: loadingSubscription } = useSubscription()

    if (!flowId)
        return (<RedirectToPage path="/flows" mode="navigate" />)

    if (!sessionId)
        return (<RedirectToPage path={`/flows/setup/${flowId}`} mode="navigate" />)

    if (loadingFlow || loadingSession || loadingUser || loadingSubscription)
        return (<></>)

    const step = session && session.fileContent ? 2 : 1

    if (flow && session && user && subscription) {
        return <FlowSettings
            user={user}
            subscription={subscription}
            flow={flow}
            link={session.link}
            room={session.room}
            step={step}
            sessionId={sessionId}
            file={session.fileName}
            content={session.fileContent}
        />
    }

    return (<></>)

}

export const FlowSetup = () => {
    const { flowId } = useParams()
    const [searchParams] = useSearchParams();

    const initialLink = searchParams.get('link')
    const room = searchParams.get('room')

    const { data: flow, isLoading } = useFlow(flowId ?? '')
    const { data: user, isLoading: loadingUser } = useUser()
    const { data: subscription, isLoading: loadingSubscription } = useSubscription()
    const { isLoading: loadingUsage, data: usage } = useUsage()
    const {isLoading: loadingFreeProduct, data: freeProduct} = useFreeProduct()

    if (!flowId)
        return (<RedirectToPage path="/flows" mode="navigate" />)

    if (isLoading || loadingUser || loadingSubscription || loadingUsage || loadingFreeProduct || !subscription || !usage || !freeProduct)
        return (<></>)

    const { recordsRemain, exportsRemain, recordsIsUnlimited, exportsIsUnlimited } = calculateBillingStats(subscription, usage, freeProduct);

    const step = room ? 1 : 0

    let error = null;
    let errorLink = null;
    if (subscription?.product && usage) {

        if (!recordsIsUnlimited && recordsRemain <= 0) {
            error = "Maximum records limit used. Please upgrade your plan."
            errorLink = '/billing'
        }

        if(!exportsIsUnlimited || subscription.product.productId === "prod_PQB1Cp2nKQAaQM") {
            if (exportsRemain <= 0) {
                error = "Maximum exports limit used. Please upgrade your plan."
                errorLink = '/billing'
            }
        }
    }

    if (error && user && subscription) {
        return (<ErrorHandler
            error={error}
            errorLink={errorLink}
            user={user}
            subscription={subscription}
            flow={flow}
        />)
    }

    if (flow && user && subscription) {
        return <FlowSettings
            user={user}
            subscription={subscription}
            flow={flow}
            link={initialLink}
            room={room}
            step={step}
            sessionId={null}
            file={null}
            content={null}
        />
    }

    return (<></>)
}