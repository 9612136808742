import React from 'react';
import Avatar from "@mui/joy/Avatar";
import AvatarGroup from "@mui/joy/AvatarGroup";
import Stack from "@mui/joy/Stack";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import Typography from "@mui/joy/Typography";
import StarOutlinedIcon from '@mui/icons-material/StarOutlined';
import { trackOpenBillingPage } from "api/tracking";
import { useNavigate } from "react-router-dom";
import { User } from "api/users";
import { Subscription } from "api/billing";
import { Flow } from "api/flows";


interface IErrorHandlerProps {
    error: string,
    errorLink: string | null,
    user: User,
    subscription: Subscription,
    flow?: Flow,
}

const ErrorHandler = (props: IErrorHandlerProps) => {
    const {
        error,
        errorLink,
        user,
        subscription,
        flow
    } = props;
    const navigate = useNavigate()

    return (
        <Box
            sx={{
                my: 'auto',
                py: 2,
                pb: 5,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                width: 900,
                maxWidth: '100%',
                mx: 'auto',
                borderRadius: 'sm',
                '& form': {
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                }
            }}
        >
            <Card>
                {flow ?
                    <Stack gap={4} sx={{ mb: 2 }}>
                        <Stack gap={1}>
                            <Typography level="h3" startDecorator={
                                <AvatarGroup
                                    size="sm"
                                    sx={{ '--AvatarGroup-gap': '-8px', '--Avatar-size': '40px' }}
                                >
                                    <Avatar
                                        src={flow.logo}
                                    />
                                </AvatarGroup>
                            }>{flow.title}</Typography>
                            <Typography level="body-sm">
                                {flow.shortDescription}
                            </Typography>
                        </Stack>
                    </Stack> : null
                }

                <Box>
                    <Typography
                        variant="soft"
                        color="danger"
                        fontSize="body-md"
                        sx={{ '--Typography-gap': '0.5rem', p: 1, textAlign: 'center' }}
                    >
                        {error}
                    </Typography>

                    <Box sx={{
                        display: 'flex',
                        flex: 1,
                        gap: 2,
                        justifyContent: 'center',
                        marginTop: 2
                    }}>
                        {
                            errorLink == '/billing'
                                ?
                                <Button
                                    startDecorator={<StarOutlinedIcon />}
                                    color="primary"
                                    onClick={
                                        () => {
                                            trackOpenBillingPage(user, subscription, 'result')
                                            navigate('/billing')
                                        }
                                    }
                                >
                                    Upgrade Plan
                                </Button> : null
                        }
                    </Box>
                </Box>
            </Card>
        </Box>
    );
}

export default ErrorHandler;