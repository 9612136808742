import * as React from 'react';
import { useNavigate } from 'react-router-dom';

export const RedirectToPage = ({ path, mode } : { path: string, mode: 'navigate' | 'location' }) => {
    const navigate = useNavigate()

    React.useEffect(()=>{
        if(mode === "navigate"){
            navigate(path)
        }
        else if( mode === "location"){
            window.location.href=path
        }
    })

    return (<></>)
}