import React from 'react';
import { User, useUser } from 'api/users';
import { Preloader } from 'components/Preloader/Preloader';
import { useNavigate } from "react-router-dom";
import SignInPage from 'pages/Security/SignInPage';
import { getCookie, setCookie } from 'utils/cookiesUtils';
import { RedirectToPage } from 'pages/Security/RedirectTo';

const tokenKey = 'PAVUKAT'

interface AuthContextType {
    user: User | null
    act: string | null
    signout: (callback: VoidFunction) => void
}

export const AuthContext = React.createContext<AuthContextType>(null!)

export const clearAuth = () => {    
    setCookie(tokenKey, '')
}

export const setAuthToken = (token: string) => {
    setCookie(tokenKey, token, 30)
}

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    const [user, setUser] = React.useState<User | null>(null)

    const signout = (callback: VoidFunction) => {
        setUser(null)
        clearAuth()
        if(callback)
            callback()
    }

    const handleUser = (data?: User, error?: any) => {
        if (data) {
            setUser(data)
        }
    } 
    
    const accessToken = getCookie(tokenKey)

    const userQuery = useUser({
        onSettled: handleUser,
        refetchOnWindowFocus: false,
        retry: false,
    })

    const value = { user, signout, act:accessToken }

    if (
        userQuery.isLoading ||
        userQuery.isIdle ||
        (userQuery.isSuccess && !user)
    ) {
        return <Preloader />
    }

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
    return React.useContext(AuthContext)
}

export const RequireAuth = ({ children, mode }: { children: JSX.Element, mode: 'page' | 'redirect' | 'empty' }) => {
    const auth = useAuth()

    const authorized = !!auth.user?.userId

    return (authorized ? <>{children}</> : mode === 'redirect' ? <RedirectToPage path='/login' mode='location' /> : mode === 'page' ? <><SignInPage /></> : <></>)
}

export const CheckAuth = () => {
    const accessToken = getCookie(tokenKey)
    const state = accessToken ? 'TOKEN FOUND' : 'TOKEN NOT FOUND'
    console.log(state)
    return <></>
}

export const FakeAuth = ({token}:{token?: string | undefined}) => {
    let accessToken = getCookie(tokenKey)
    
    if (token && (!accessToken || accessToken != token)) {
        accessToken = token
        setAuthToken(accessToken)
    }

    return <></>
}
