import React from 'react'
import Header from './components/Header/Header';
import { AuthContext, RequireAuth } from 'context/Auth';
import Box from '@mui/joy/Box';
import { Sidebar } from 'components/Sidebar/Sidebar';
import { MixpanelInit } from 'components/Mixpanel/MixpanelInit';
import { LogRocketInit } from 'components/Logrocket/LogrocketInit';
import { ProfileChecker } from 'components/ProfileChecker/ProfileChecker';

export const ProtectedPage = ({ onModeChange, children }: { onModeChange: (mode: string) => void, children: JSX.Element }) => {
  return (<>
    <RequireAuth mode='redirect'>
      <Box sx={{ display: 'flex', minHeight: '100dvh' }}>
        <Header />
        <AuthContext.Consumer>
          {
            ctx =>
              <>
                <ProfileChecker user={ctx.user} />
                <Sidebar user={ctx.user} onModeChange={onModeChange} />
              </>
          }
        </AuthContext.Consumer>
        <Box
          component="main"
          className="MainContent"
          sx={{
            px: {
              xs: 2,
              md: 4,
            },
            pt: {
              xs: 'calc(12px + var(--Header-height))',
              sm: 'calc(12px + var(--Header-height))',
              md: 2,
            },
            pb: {
              xs: 2,
              sm: 2,
              md: 2,
            },
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            minWidth: 0,
            height: '100dvh',
            gap: 1,
            overflow: 'auto',
            '& form': {
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }
          }}
        >
          {children}
        </Box>
      </Box>
    </RequireAuth>
  </>)
}