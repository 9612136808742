import { getCookie, setCookie } from 'utils/cookiesUtils'; 

export const isPathSelected = (pathname: string, pattern: string) => {
  return pathname.startsWith(pattern);
}

export const openSidebar = () => {
    if (typeof document !== 'undefined') {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.setProperty('--SideNavigation-slideIn', '1');
    }
  };
  
  export const closeSidebar = () => {
    if (typeof document !== 'undefined') {
      document.documentElement.style.removeProperty('--SideNavigation-slideIn');
      document.body.style.removeProperty('overflow');
    }
  };
  
  export const toggleSidebar = () => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      const slideIn = window
        .getComputedStyle(document.documentElement)
        .getPropertyValue('--SideNavigation-slideIn');
      if (slideIn) {
        closeSidebar();
      } else {
        openSidebar();
      }
    }
  };

  export const useSidebar = () => {
    return {
        isExpanded : () => {
            const expandedCookie = getCookie('sidebar')
            return !!(expandedCookie && expandedCookie==='expanded')
        },    
        setExpanded : (expanded:boolean) => {
            setCookie('sidebar', expanded ? 'expanded' : 'minified', 365)
        }
    }
}