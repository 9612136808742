import { Check, KeyboardArrowRight, SupportAgent } from "@mui/icons-material"
import Box from "@mui/joy/Box"
import Button from "@mui/joy/Button"
import Card from "@mui/joy/Card"
import CardActions from "@mui/joy/CardActions"
import Chip from "@mui/joy/Chip"
import Divider from "@mui/joy/Divider"
import List from "@mui/joy/List"
import ListItem from "@mui/joy/ListItem"
import ListItemDecorator from "@mui/joy/ListItemDecorator"
import Slider from "@mui/joy/Slider"
import Stack from "@mui/joy/Stack"
import Tab, { tabClasses } from "@mui/joy/Tab"
import TabList from "@mui/joy/TabList"
import TabPanel from "@mui/joy/TabPanel"
import Tabs from "@mui/joy/Tabs"
import Typography from "@mui/joy/Typography"
import { Avatar } from "@mui/material"
import { Product, Subscription } from "api/billing"
import { trackAccountUpgradeStart } from "api/tracking"
import { User } from "api/users"
import { PricingPremiumIcon } from "assets/images/PricingPremiumIcon"
import { PricingProIcon } from "assets/images/PricingProIcon"
import { PricingStarterIcon } from "assets/images/PricingStarterIcon"
import _ from "lodash"
import React, { useMemo, useState } from "react"
import { unlimited } from "utils/billing"

const marks = [
    {
        value: 10000,
        label: '10 000',
    },
    {
        value: 20000,
        label: '20 000',
    },
    {
        value: 30000,
        label: '30 000',
    },
    {
        value: 40000,
        label: '40 000',
    },
    {
        value: 50000,
        label: '50 000',
    }
];

const valueText = (value: number) => {
    return `${value} records`
}

const RecordsSlider = ({ onChange }: { onChange: (value: number) => void }) => {
    return (
        <Box sx={{ width: '100%', p: 2 }}>
            <Slider
                aria-label="Always visible"
                defaultValue={20000}
                getAriaValueText={valueText}
                valueLabelFormat={(value, index) => {
                    return `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} records`
                }}
                step={10000}
                marks={marks}
                valueLabelDisplay="on"
                min={10000}
                max={50000}
                onChange={(event, value) => {
                    onChange(value as number)
                }}
            />
        </Box>
    );
}

export const BillingPlansGrid = ({ user, subscription, products }: { user: User, subscription: Subscription, products: Product[] }) => {
    const [packages, setPackages] = useState(2)

    const plans = useMemo(() => _.flatten(_.map(_.filter(products ?? [], (p: Product) => {
        return p.productId !== 'free' && p.type !== 'package'
    }), (p) => {
        return [{
            id: `${p.productId}_monthly`,
            name: p.name,
            amount: p.prices.monthly.amount,
            period: 'monthly',
            link: p.prices.monthly.link,
            product: p
        }, {
            id: `${p.productId}_annual`,
            name: p.name + ' Annual',
            amount: p.prices.annual.amount,
            period: 'annual',
            link: p.prices.annual.link,
            product: p
        }]
    })), [products, subscription])

    const upgradeSubscription = (planId: string) => {
        const plan = _.find(plans, (p) => p.id == planId)
        if (plan) {
            trackAccountUpgradeStart(user, subscription, plan.product, 'result')
            window.location.href = `${plan?.link}?prefilled_email=${user.email}&client_reference_id=${user.userId}`
        }
    }

    const monthlyPlans = _.filter(plans, (p) => p.period == 'monthly')
    const annualPlans = _.filter(plans, (p) => p.period == 'annual')

    const flexProduct = _.find(products, (p: Product) => p.type === 'package')

    const openSupportChat = () => {
        const w: any = window
        const tc: any = w.tidioChatApi
        if (tc) {
            tc.display(true)
            tc.open()
        }
    }

    return (
        <Box sx={{
            width: '100%'
        }}>
            <Tabs aria-label="Pricing plan"
                defaultValue={0}
                sx={{
                    borderRadius: 'lg',
                    boxShadow: 'sm',
                    overflow: 'auto',
                }}>
                <TabList disableUnderline
                    sx={{
                        p: 1,
                        gap: 0.5,
                        my: 1,
                        mx: 'auto',
                        width: 'fit-content',
                        borderRadius: 'xl',
                        bgcolor: 'background.level1',
                        [`& .${tabClasses.root}[aria-selected="true"]`]: {
                            boxShadow: 'sm',
                            bgcolor: 'background.surface',
                        },
                    }}>
                    <Tab disableIndicator variant="soft" >Monthly</Tab>
                    <Tab disableIndicator variant="soft">Annual
                        <Chip
                            color="success"
                            variant="solid"
                        >2 month free</Chip></Tab>
                    <Tab disableIndicator variant="soft" >One-time</Tab>
                </TabList>
                <TabPanel value={0}>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'grid',
                            gridTemplateColumns: 'repeat(auto-fill, minmax(min(100%, 300px), 1fr))',
                            gap: 2,
                        }}
                    >
                        {
                            monthlyPlans.map((plan, index) => {
                                return (<Card size="lg" variant="outlined" key={plan.id}>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        [`& svg`]: {
                                            height: 50,
                                            width: 'auto'
                                        }
                                    }}>
                                        {
                                            index === 0 ?
                                                <PricingStarterIcon />
                                                : index === 1
                                                    ? <PricingProIcon />
                                                    : <PricingPremiumIcon />
                                        }
                                    </Box>
                                    {
                                        index === 1 ?
                                            <Chip size="sm" variant="solid" color="primary"
                                                sx={{ position: 'absolute', top: '0', right: '0' }}>
                                                MOST POPULAR
                                            </Chip>
                                            : <></>
                                    }
                                    <Typography level="h2" sx={{
                                        textAlign: 'center'
                                    }}>{plan.name.replace('Pavuk.AI ', '')}</Typography>
                                    <Divider inset="none" />
                                    <CardActions>
                                        <Typography level="title-lg" sx={{ mr: 'auto' }}>
                                            {`$${plan.amount} `}
                                            <Typography fontSize="sm" textColor="text.tertiary">
                                                / month
                                            </Typography>
                                        </Typography>

                                        {
                                            subscription.product.productId === plan.product.productId
                                                ? <Button
                                                    variant="outlined"
                                                    color='neutral'>Current plan</Button>
                                                : <Button
                                                    variant="solid"
                                                    color="primary"
                                                    endDecorator={<KeyboardArrowRight />}
                                                    onClick={() => {
                                                        upgradeSubscription(plan.id)
                                                    }}
                                                >
                                                    Start now
                                                </Button>}
                                    </CardActions>
                                    <Divider inset="none" />
                                    <List size="sm" sx={{ mx: 'calc(-1 * var(--ListItem-paddingX))' }}>
                                        <ListItem>
                                            <ListItemDecorator>
                                                <Check />
                                            </ListItemDecorator>
                                            {plan.product.limits.records.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} Records
                                        </ListItem>
                                        <ListItem>
                                            <ListItemDecorator>
                                                <Check />
                                            </ListItemDecorator>
                                            {plan.product.limits.exports < unlimited ? plan.product.limits.exports.toString() : 'Unlimited' } Exports
                                        </ListItem>
                                        <ListItem>
                                            <ListItemDecorator>
                                                <Check />
                                            </ListItemDecorator>
                                            Email & Chat Support
                                        </ListItem>
                                    </List>
                                </Card>)
                            })
                        }
                    </Box>
                </TabPanel>
                <TabPanel value={1}>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'grid',
                            gridTemplateColumns: 'repeat(auto-fill, minmax(min(100%, 300px), 1fr))',
                            gap: 2,
                        }}
                    >
                        {
                            annualPlans.map((plan, index) => {
                                return (<Card size="lg" variant="outlined" key={plan.id}>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        [`& svg`]: {
                                            height: 50,
                                            width: 'auto'
                                        }
                                    }}>
                                        {
                                            index === 0 ?
                                                <PricingStarterIcon />
                                                : index === 1
                                                    ? <PricingProIcon />
                                                    : <PricingPremiumIcon />
                                        }
                                    </Box>
                                    {
                                        index === 1 ?
                                            <Chip size="sm" variant="solid" color="primary"
                                                sx={{ position: 'absolute', top: '0', right: '0' }}>
                                                MOST POPULAR
                                            </Chip>
                                            : <></>
                                    }
                                    <Typography level="h2" sx={{
                                        textAlign: 'center'
                                    }}>{plan.name.replace('Pavuk.AI ', '')}</Typography>
                                    <Divider inset="none" />
                                    <CardActions>
                                        <Typography level="title-lg" sx={{ mr: 'auto' }}>
                                            {`$${Math.floor(plan.amount / 12)} `}
                                            <Typography fontSize="sm" textColor="text.tertiary">
                                                / month
                                            </Typography>
                                        </Typography>

                                        {
                                            subscription.product.productId === plan.product.productId
                                                ? <Button
                                                    variant="outlined"
                                                    color='neutral'>Current plan</Button>
                                                : <Button
                                                    variant="solid"
                                                    color="primary"
                                                    endDecorator={<KeyboardArrowRight />}
                                                    onClick={() => {
                                                        upgradeSubscription(plan.id)
                                                    }}
                                                >
                                                    Start now
                                                </Button>}
                                    </CardActions>
                                    <Typography level="body-sm">paid as ${plan.amount} / yearly</Typography>
                                    <Divider inset="none" />
                                    <List size="sm" sx={{ mx: 'calc(-1 * var(--ListItem-paddingX))' }}>
                                        <ListItem>
                                            <ListItemDecorator>
                                                <Check />
                                            </ListItemDecorator>
                                            {plan.product.limits.records.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} Records
                                        </ListItem>
                                        <ListItem>
                                            <ListItemDecorator>
                                                <Check />
                                            </ListItemDecorator>
                                            {plan.product.limits.exports < unlimited ? plan.product.limits.exports.toString() : 'Unlimited' } Exports
                                        </ListItem>
                                        <ListItem>
                                            <ListItemDecorator>
                                                <Check />
                                            </ListItemDecorator>
                                            Email & Chat Support
                                        </ListItem>
                                    </List>
                                </Card>)
                            })
                        }
                    </Box>
                </TabPanel>
                <TabPanel value={2}>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            gap: 2,
                        }}
                    >
                        {
                            flexProduct ?
                                <>
                                    <Card size="lg" variant="outlined">
                                        <Box sx={{
                                            display: 'flex',
                                            width: '250px',
                                            justifyContent: 'center',
                                            [`& svg`]: {
                                                height: 50,
                                                width: 'auto'
                                            }
                                        }}>
                                            <PricingStarterIcon />
                                        </Box>
                                        <Chip size="sm" variant="solid" color="primary"
                                            sx={{ position: 'absolute', top: '0', right: '0' }}>
                                            GOOD TO START
                                        </Chip>
                                        <Typography level="h2" sx={{
                                            textAlign: 'center'
                                        }}>{flexProduct.name.replace('Pavuk.AI ', '')}</Typography>
                                        <Divider inset="none" />
                                        <CardActions>
                                            <Typography level="title-lg" sx={{ mr: 'auto' }}>
                                                {`$${flexProduct.prices.package.amount} `}
                                                <Typography fontSize="sm" textColor="text.tertiary"> / package
                                                </Typography>
                                            </Typography>
                                            <Button
                                                variant="solid"
                                                color="primary"
                                                endDecorator={<KeyboardArrowRight />}
                                                onClick={() => {
                                                    trackAccountUpgradeStart(user, subscription, flexProduct, 'result')
                                                    window.location.href = `${flexProduct.prices.package.link}?prefilled_email=${user.email}&client_reference_id=${user.userId}`
                                                }}
                                            >
                                                Buy now
                                            </Button>
                                        </CardActions>
                                        <Divider inset="none" />
                                        <Typography level='title-md'>Every package includes</Typography>
                                        <List size="sm" sx={{ mx: 'calc(-1 * var(--ListItem-paddingX))' }}>
                                            <ListItem>
                                                <ListItemDecorator>
                                                    <Check />
                                                </ListItemDecorator>
                                                {flexProduct.limits.records.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} Records
                                            </ListItem>
                                            <ListItem>
                                                <ListItemDecorator>
                                                    <Check />
                                                </ListItemDecorator>
                                                {flexProduct.limits.exports} Exports
                                            </ListItem>
                                        </List>
                                    </Card>
                                    <Card size="lg" variant="outlined">
                                        <Typography level='body-lg'>Pavuk AI Flex provides a great option to use premium export features and easily manage your account limits with one-time payments per package without requirement of monthly payment</Typography>
                                        <Divider inset="none" />
                                        <CardActions>
                                            <Typography level='h2' sx={{ mr: 'auto' }}>
                                                {`$${flexProduct.prices.package.amount * packages}`}
                                                <Typography fontSize="sm" textColor="text.tertiary"> / {`${(flexProduct.limits.records * packages).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}`} records / {`${flexProduct.limits.exports * packages}`} exports
                                                </Typography>
                                            </Typography>
                                            <Button
                                                variant="solid"
                                                color="primary"
                                                endDecorator={<KeyboardArrowRight />}
                                                onClick={() => {
                                                    trackAccountUpgradeStart(user, subscription, flexProduct, 'result')
                                                    window.location.href = `${flexProduct.prices.package.link}?prefilled_email=${user.email}&client_reference_id=${user.userId}`
                                                }}
                                            >
                                                Buy now
                                            </Button>
                                        </CardActions>
                                        <RecordsSlider onChange={(value) => {
                                            setPackages(Math.ceil(value / 10000))
                                        }} />
                                        <Divider inset="none" />
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'center'
                                        }}>
                                            <Button
                                                color="primary"
                                                variant="soft"
                                                startDecorator={
                                                    <SupportAgent />
                                                }
                                                onClick={openSupportChat}
                                            >
                                                Contact Sales
                                            </Button>
                                        </Box>
                                    </Card>
                                </>
                                : <></>
                        }
                    </Box>
                </TabPanel>
            </Tabs>
        </Box>

    )
}