import { FlowSession, runFlow } from "api/flows";
import { RedirectToPage } from "pages/Security/RedirectTo";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

export const FlowStart = () => {
    const [sessionId, setSessionId] = useState<string | null>(null)
    const [searchParams, setSearchParams] = useSearchParams()

    const navigate = useNavigate()

    const { flowId } = useParams()

    const initialLink = searchParams.get('link')

    const { mutateAsync: startFlow, isLoading: inProgress } = useMutation(runFlow)

    useEffect(() => {
        if(!sessionId){
            startFlow({
                flowId: flowId ?? '',
                flowLink: initialLink ?? ''
            }, {
                onSuccess: (data: FlowSession) => {
                    setSessionId(data.sessionId)
                }
            })
        } else {
            navigate(`/flows/setup/${flowId}/${sessionId}`)
        }
    }, [sessionId])

    if (!flowId)
        return (<RedirectToPage path="/flows" mode="navigate" />)    
    
    return (<></>)
}