import * as React from 'react';
import GlobalStyles from '@mui/joy/GlobalStyles';
import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import Chip from '@mui/joy/Chip';
import Divider from '@mui/joy/Divider';
import IconButton from '@mui/joy/IconButton';
import LinearProgress from '@mui/joy/LinearProgress';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton, { listItemButtonClasses } from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import Stack from '@mui/joy/Stack';

//icons
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import SupportRoundedIcon from '@mui/icons-material/SupportRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import LocalGroceryStoreOutlinedIcon from '@mui/icons-material/LocalGroceryStoreOutlined';

import { PavukLogoIcon } from 'components/icons/PavukLogoIcon';

import ColorSchemeToggle from '../ColorSchemeToggle/ColorSchemeToggle';
import { closeSidebar, isPathSelected } from './utils';
import { prettifyNumber } from '../../utils/formattingUtils';


import {
  Link as RouterLink, useLocation, useNavigate
} from 'react-router-dom';

import { User, useUser } from 'api/users';
import { useAuth } from 'context/Auth';
import { useFreeProduct, useSubscription, useUsage } from 'api/billing';
import { StarOutlined } from '@mui/icons-material';
import { trackOpenBillingPage } from 'api/tracking';
import { calculateBillingStats } from 'utils/billing';

function Toggler({
  defaultExpanded = false,
  renderToggle,
  children,
}: {
  defaultExpanded?: boolean;
  children: React.ReactNode;
  renderToggle: (params: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  }) => React.ReactNode;
}) {
  const [open, setOpen] = React.useState(defaultExpanded);
  return (
    <React.Fragment>
      {renderToggle({ open, setOpen })}
      <Box
        sx={{
          display: 'grid',
          gridTemplateRows: open ? '1fr' : '0fr',
          transition: '0.2s ease',
          '& > *': {
            overflow: 'hidden',
          },
        }}
      >
        {children}
      </Box>
    </React.Fragment>
  );
}

const UsageWidget = () => {
  const navigate = useNavigate()
  const { isLoading: loadingSubscription, data: subscription } = useSubscription()
  const { isLoading, data: usage } = useUsage()
  const { isLoading: loadingUser, data: user } = useUser()
  const {isLoading: loadingFreeProduct, data: freeProduct} = useFreeProduct()

  if ((isLoading || loadingSubscription || loadingUser || loadingFreeProduct) || (!usage || !subscription || !user || !freeProduct))
    return (<></>)

  const { recordsLimit, exportsLimit, recordsPercent, exportsPercent, closeToLimit, usedLimit, isPaid, recordsIsUnlimited, exportsIsUnlimited } = calculateBillingStats(subscription, usage, freeProduct);

  return (<Card
    invertedColors
    variant="soft"
    color={closeToLimit || !isPaid ? "warning" : "neutral"}
    size="sm"
    sx={{ boxShadow: 'none' }}
  >
    <Stack direction="row" alignItems="center" spacing={0.5}>
      <Typography level="title-sm">Pavuk AI</Typography>
      <Typography level="title-sm" sx={{ color: "#4393e4" }}>{usage.product.name.replace('Pavuk.AI ', '')} Plan</Typography>
    </Stack>
    <Stack
      direction="row"
      justifyContent="space-between"
      spacing={2}>
      <Typography level='title-sm'>Records</Typography>
      <Typography level='title-sm'>{prettifyNumber(usage.usage.records, false)} / {recordsIsUnlimited ? 'Unlimited' : prettifyNumber(recordsLimit, false)}</Typography>
    </Stack>
    {
      recordsIsUnlimited
      ? <></>
      : 
      <LinearProgress variant="outlined" value={recordsPercent} determinate sx={{ my: 1 }} />
    }

    <Stack
      direction="row"
      justifyContent="space-between"
      spacing={2}>
      <Typography level='title-sm'>Exports</Typography>
      <Typography level='title-sm'>{prettifyNumber(usage.usage.exports, false)} / {exportsIsUnlimited ? 'Unlimited' : prettifyNumber(exportsLimit, false)}</Typography>
    </Stack>
    {
      exportsIsUnlimited?
      <></>
      : <LinearProgress variant="outlined" value={exportsPercent} determinate sx={{ my: 1 }} />
    }
    {
      closeToLimit
        ? <><Typography level="body-xs">
          You used {usedLimit}% of your available exports limit. Need more?
        </Typography>
          <Button size="sm" variant="solid"
            onClick={() => {
              trackOpenBillingPage(user, subscription, 'sidepanel')
              navigate('/billing')
            }}>
            Upgrade Plan
          </Button></>
        : <><Button size="sm" variant="solid"
          startDecorator={
            isPaid ?
              <CreditCardOutlinedIcon />
              : <StarOutlined />
          }
          onClick={() => {
            trackOpenBillingPage(user, subscription, 'sidepanel')
            navigate('/billing')
          }}>
          {isPaid ? 'Manage Billing' : 'Upgrade Plan'}
        </Button>
        </>
    }

  </Card>)
}


export const Sidebar = ({
  user,
  onModeChange
}: {
  user: User | null,
  onModeChange: (mode: string) => void
}) => {

  const location = useLocation();
  const { pathname } = location;
  const auth = useAuth()

  return (
    <Sheet
      className="Sidebar"
      sx={{
        position: {
          xs: 'fixed',
          md: 'sticky',
        },
        transform: {
          xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
          md: 'none',
        },
        transition: 'transform 0.4s, width 0.4s',
        zIndex: 10000,
        height: '100dvh',
        width: 'var(--Sidebar-width)',
        top: 0,
        p: 2,
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        borderRight: '1px solid',
        borderColor: 'divider',
      }}
    >
      <GlobalStyles
        styles={(theme) => ({
          ':root': {
            '--Sidebar-width': '220px',
            [theme.breakpoints.up('lg')]: {
              '--Sidebar-width': '240px',
            },
          },
        })}
      />
      <Box
        className="Sidebar-overlay"
        sx={{
          position: 'fixed',
          zIndex: 9998,
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          opacity: 'var(--SideNavigation-slideIn)',
          backgroundColor: 'var(--joy-palette-background-backdrop)',
          transition: 'opacity 0.4s',
          transform: {
            xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
            lg: 'translateX(-100%)',
          },
        }}
        onClick={() => closeSidebar()}
      />
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <PavukLogoIcon />
        <Typography level="title-lg">Pavuk AI</Typography>
        <ColorSchemeToggle sx={{ ml: 'auto' }} onModeChange={onModeChange} />
      </Box>
      <Box
        sx={{
          minHeight: 0,
          overflow: 'hidden auto',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          [`& .${listItemButtonClasses.root}`]: {
            gap: 1.5,
          },
        }}
      >
        <List
          size="sm"
          sx={{
            gap: 1,
            '--List-nestedInsetStart': '30px',
            '--ListItem-radius': (theme) => theme.vars.radius.sm,
          }}
        >
          <ListItem>
            <ListItemButton
              selected={isPathSelected(pathname, '/apps') || pathname === '/'}
              role="menuitem"
              component={RouterLink} to="/apps">
              <DashboardRoundedIcon />
              <ListItemContent>
                <Typography level="title-sm">Apps</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>

          <ListItem>
            <ListItemButton
              selected={isPathSelected(pathname, '/downloads')}
              role="menuitem"
              component={RouterLink} to="/downloads">
              <CloudDownloadIcon />
              <ListItemContent>
                <Typography level="title-sm">Downloads</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>
        </List>
        <UsageWidget />
      </Box>
      <Divider />
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <Box sx={{ minWidth: 0, flex: 1 }}>
          <Typography level="title-sm">{user?.profile?.name} {user?.profile?.lastName}</Typography>
          <Typography level="body-xs" sx={
            {
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }
          }>{user?.email}</Typography>
        </Box>
        <IconButton size="sm" variant="plain" color="neutral" onClick={() => {
          auth.signout(() => {
            window.location.href = '/login'
          })
        }}>
          <LogoutRoundedIcon />
        </IconButton>
      </Box>
    </Sheet>
  );
}