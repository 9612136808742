import { setAuthToken } from "context/Auth";
import React from "react";
import { useSearchParams } from "react-router-dom";

export default function AuthPage(){
    const [searchParams] = useSearchParams()
    
    React.useEffect(()=>{
        const token = searchParams.get('at')

        if(token && token.length > 0){
            setAuthToken(token)
            window.location.href = '/'
        }
    })

    return (<></>)
}