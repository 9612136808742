import { CheckRounded, Rocket, RocketLaunch, RocketOutlined, TaskAltOutlined } from "@mui/icons-material";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import { useSubscription } from "api/billing";
import { trackPaymentSuccess } from "api/tracking";
import { useUser } from "api/users";
import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

export const PaymentSuccessPage = () => {
    const { isLoading, data: subscription } = useSubscription()
    const { isLoading: isUserLoading, data: user } = useUser()

    const navigate = useNavigate()

    useEffect(()=>{
        if(user && subscription){
            trackPaymentSuccess(user, subscription)
        }
    }, [user, subscription])

    if(isLoading || isUserLoading)
        return (<></>)

    return (<>
    <Box
            sx={{
                my: 'auto',
                py: 2,
                pb: 5,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                width: 900,
                maxWidth: '100%',
                mx: 'auto',
                borderRadius: 'sm',
                '& form': {
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                }
            }}
        >
            <Card sx={{
                alignItems:'center',
                justifyContent:'center'
            }}>
                <Typography level='h3'
                startDecorator={
                    <TaskAltOutlined color="success" />
                }>
                    Congratulations!
                </Typography>
                <Typography level='h3'>
                    Payment completed and your account is ready to use
                </Typography>
                <Typography level='body-md'>Now you can enjoy even more powerful Pavuk AI features</Typography>
                {
                    subscription && subscription.product.type == 'subscription'
                    ? 
                    <Typography>{subscription?.product.limits.records} records and {subscription?.product.limits.exports} exports monthly</Typography>
                    : <></>
                }
                <Button 
                variant='solid'
                color='primary'
                startDecorator={<RocketLaunch />}
                onClick={()=>{
                    navigate('/apps')
                }}>Start new export</Button>
            </Card>
        </Box>
    </>)
}