import { Product, Subscription, Usage } from "api/billing"

export const unlimited = 1000000000

export const calculateBillingStats = (subscription: Subscription, usage: Usage, freeProduct: Product) => {
    const isPaid = subscription?.product.productId !== 'free'

    const recordsLimit = Math.max(subscription.records || 0, usage.product.limits.records, freeProduct.limits.records)
    const exportsLimit = Math.max(subscription.exports || 0, usage.product.limits.exports, freeProduct.limits.exports)

    const recordsIsUnlimited = recordsLimit === unlimited
    const exportsIsUnlimited = exportsLimit === unlimited

    const recordsRemain = recordsIsUnlimited ? unlimited : recordsLimit - usage.usage.records
    const exportsRemain = exportsIsUnlimited ? unlimited : exportsLimit - usage.usage.exports

    const recordsPercent = recordsIsUnlimited ? 0 : Math.min(100, Math.ceil(usage.usage.records / recordsLimit * 100))
    const exportsPercent = exportsIsUnlimited ? 0 : Math.min(100, Math.ceil(usage.usage.exports / exportsLimit * 100))
    const closeToLimit = recordsPercent >= 80 || exportsPercent >= 80

    const usedLimit = Math.max(recordsPercent, exportsPercent)

    return { recordsLimit, exportsLimit, recordsRemain, exportsRemain, recordsPercent, exportsPercent, closeToLimit, usedLimit, isPaid, recordsIsUnlimited, exportsIsUnlimited }
}