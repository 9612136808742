import React from 'react'
import axios from 'axios'
import { getCookie } from 'utils/cookiesUtils'

export const AxiosDefaults = () => {
    axios.defaults.baseURL = 'https://api.pavuk.ai'
    const authToken = getCookie('PAVUKAT')

    if (authToken) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`
    }
    
    axios.defaults.headers.post['Content-Type'] = 'application/json'
    return <></>
}
