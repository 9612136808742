import mixpanel from "mixpanel-browser"
import { Product, Subscription } from "./billing"
import { Profile, User } from "./users"
import { Flow, FlowSession } from "./flows"

export interface TrackEventModel {
    event_name: string
    event_params: { [key: string]: string }
}

export interface TrackEventData<T> {
    event_name: string
    event_params: T
}

export const trackEvent = async (event: TrackEventModel | TrackEventData<any>) => {
    mixpanel.track(event.event_name, event.event_params)
}

interface EventModelBase {
    email: string
    product_id: string
    product_name: string
}

export interface SignUpEventModel {
    email: string
    source: string
}

export const trackSignUp = async (user: User, source: string) => {
    try {
        const data: TrackEventData<SignUpEventModel> = {
            event_name: 'sign_up',
            event_params: {
                email: user.email,
                source: source
            }
        }

        await trackEvent(data)
    }
    catch (error) {

    }
}

export interface ProfileSetupEventModel {
    email: string
    country: string
    role: string
}

export const trackProfileSetup = async (user: User) => {
    const data: TrackEventData<ProfileSetupEventModel> = {
        event_name: 'profile_setup',
        event_params: {
            email: user.email,
            country: (user.profile.data['country'] ?? '').toString(),
            role: (user.profile.data['role'] ?? '').toString()
        }
    }

    await trackEvent(data)
}

export interface AppSelectEventModel extends EventModelBase {
    app_id: string
    app_title: string
}

export const trackAppSelect = async (user: User, subscription: Subscription, flow: Flow) => {
    const data: TrackEventData<AppSelectEventModel> = {
        event_name: 'app_select',
        event_params: {
            email: user.email,
            product_id: subscription.product.productId,
            product_name: subscription.product.name,
            app_id: flow.id,
            app_title: flow.title
        }
    }

    await trackEvent(data)

}

export interface AppStartEventModel extends EventModelBase {
    app_id: string
    app_title: string
    crawl_url: string
    session_id: string
}

export const trackAppStart = async (user: User, subscription: Subscription, session: FlowSession) => {
    const data: TrackEventData<AppStartEventModel> = {
        event_name: 'app_start',
        event_params: {
            email: user.email,
            product_id: subscription.product.productId,
            product_name: subscription.product.name,
            app_id: session.flowId,
            app_title: session.name,
            session_id: session.sessionId,
            crawl_url: session.link
        }
    }

    await trackEvent(data)
}

export interface AppSuccessEventModel extends AppStartEventModel {
    count: number
}

export const trackAppSuccess = async (user: User, subscription: Subscription, flow: Flow, sessionId: string, link: string, count: number) => {
    const data: TrackEventData<AppSuccessEventModel> = {
        event_name: 'app_success',
        event_params: {
            email: user.email,
            product_id: subscription.product.productId,
            product_name: subscription.product.name,
            app_id: flow.id,
            app_title: flow.title,
            session_id: sessionId,
            crawl_url: link,
            count: count
        }
    }

    await trackEvent(data)
}

export interface AppErrorEventMode extends AppStartEventModel {
    error: string
}

export const trackAppError = async (user: User, subscription: Subscription, flow: Flow, sessionId: string, link: string, error: string) => {
    const data: TrackEventData<AppErrorEventMode> = {
        event_name: 'app_error',
        event_params: {
            email: user.email,
            product_id: subscription.product.productId,
            product_name: subscription.product.name,
            app_id: flow.id,
            app_title: flow.title,
            session_id: sessionId,
            crawl_url: link,
            error: error
        }
    }

    await trackEvent(data)
}

export interface FileDownloadEventModel extends AppSuccessEventModel {
    source: 'result' | 'downloads' | 'email'
}

export const trackDownload = async (user: User, subscription: Subscription, session: FlowSession, count: number, source: 'result' | 'downloads' | 'email') => {
    const data: TrackEventData<FileDownloadEventModel> = {
        event_name: 'file_download',
        event_params: {
            email: user.email,
            product_id: subscription.product.productId,
            product_name: subscription.product.name,
            app_id: session.flowId,
            app_title: session.name,
            session_id: session.sessionId,
            crawl_url: session.link,
            count: count,
            source: source
        }
    }

    await trackEvent(data)
}

export interface OpenBillingPageEventMode extends EventModelBase {
    source: 'result' | 'sidepanel' | 'email'
}

export const trackOpenBillingPage = async (user: User, subscription: Subscription, source: 'sidepanel' | 'result' | 'email') => {
   const data : TrackEventData<OpenBillingPageEventMode> = {
        event_name: 'open_billing_page',
        event_params: {
            email: user.email,
            product_id: subscription.product.productId,
            product_name: subscription.product.name,
            source: source
        }
   }

   await trackEvent(data)
}

export interface AccountUpgradeStartEventModel extends EventModelBase {
    action: 'initial' | 'upgrade'
    to_product_id: string
    to_product_name: string
    source: 'result' | 'sidepanel' | 'limit_popup' | 'email'
}

export const trackAccountUpgradeStart = async (user: User, subscription: Subscription, toProduct: Product, source: 'result' | 'sidepanel' | 'limit_popup' | 'email') => {
    const data : TrackEventData<AccountUpgradeStartEventModel> = {
        event_name: 'account_upgrade_start',
        event_params: {
            email: user.email,
            product_id: subscription.product.productId,
            product_name: subscription.product.name,
            source: source,
            to_product_id: toProduct.productId,
            to_product_name: toProduct.name,
            action: subscription.product.productId == "free" ? "initial" : "upgrade"
        }
   }

   await trackEvent(data)
}

export interface AccountUpgradeSuccess extends EventModelBase {
    interval: 'month' | 'year'
}

export interface PaymentSuccess extends EventModelBase {
}

export const trackPaymentSuccess = async (user:User, subscription: Subscription) => {
    const data : TrackEventData<PaymentSuccess> = {
        event_name: 'payment_success',
        event_params: {
            email: user.email,
            product_id: subscription.product.productId,
            product_name: subscription.product.name
        }
   }

   await trackEvent(data)
}