import { User } from 'api/users';
import mixpanel from 'mixpanel-browser'
import React, { useEffect, useState } from 'react'


export const MixpanelInit = ({ user, debug }: { user: User | null, debug: boolean }) => {
    mixpanel.init('3fe81054104ef26dfff6f97c8e7dd11e', {debug: debug}); 
    
    if(user){
        mixpanel.identify(user.userId)
        mixpanel.people.set_once('$email', user.email)
        if(user.profile && user.profile.name && user.profile.lastName)
        {
            mixpanel.people.set_once('$name', `${user.profile.name} ${user.profile.lastName}`)
        }
    }

    return (<></>)
}