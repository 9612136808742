import { User } from 'api/users';
import * as React from 'react';

export const ProfileChecker = ({ user }: {user: User | null}) => {

    React.useEffect(()=>{
        if(!(user?.profile.name)){
            window.location.href = "/profile"
        }
    })

    return (<></>)
}