export const addSpaceAterSymbol = (value: number) => {
  let valueStr = value.toString();

  return valueStr.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export const prettifyNumber = (value: number, isRoundValues: boolean) => {
  let thousand = 1000,
    million = 1000000,
    billion = 1000000000,
    trillion = 1000000000000;

  if (isRoundValues && value !== null && typeof value !== 'undefined') {
    if (value < thousand) {
      return String(value);
    }

    if (value >= thousand && value <= 1000000) {
      let strVal = value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1."),
        num = parseFloat(strVal),
        fixVal = num.toFixed(1);;

      if ((Number(fixVal) - parseInt(fixVal)) > 0) {
        return fixVal + 'K';
      } else {
        return parseInt(fixVal) + 'K';
      }
    }

    if (value >= million && value <= billion) {
      let strVal;

      if (value >= million && value < (10 * million)) {
        strVal = value.toString().replace(/(\d)(?=(\d{1})+(?!\d))/g, "$1.");
      } else if (value >= (10 * million) && value < (100 * million)) {
        strVal = value.toString().replace(/(\d)(?=(\d{2})+(?!\d))/g, "$1.");
      } else {
        strVal = value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
      }
      let num = parseFloat(strVal);
      let fixVal = num.toFixed(1);

      if ((Number(fixVal) - parseInt(fixVal)) > 0) {
        return fixVal + 'M';
      } else {
        return parseInt(fixVal) + 'M';
      }
    }

    if (value >= billion && value <= trillion) {
      return Math.round(value / billion) + 'B';
    } else {
      return Math.round(value / trillion) + 'T';
    }
  } else {
    return value !== null && typeof value !== 'undefined'
      ? addSpaceAterSymbol(value)
      : JSON.stringify(value);
  }
}