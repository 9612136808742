import { User } from 'api/users'
import React, { useEffect, useState } from 'react'


export const IntercomSettings = ({ user }: { user: User | null }) => {
    const w: any = window
    const [icDataSent, setIcDataStatus] = useState(false)
    const [icTs, setIcTs] = useState(0)

    useEffect(() => {
        const timer = setTimeout(() => {
            if (user && w.tidioChatApi && !icDataSent) {
                w.tidioChatApi.setVisitorData({
                    email: user.email,
                    name: `${user.profile?.name} ${user.profile?.lastName}`,
                    distinct_id: user.userId
                })
                setIcDataStatus(true)
            }
            else {
                setIcTs(icTs + 1)
            }
        }, 500);

        return () => clearTimeout(timer);

    }, [icTs])

    return (<></>)
}