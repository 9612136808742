import axios from "axios"
import { ApiError, pavukApi, twentyFourHoursInMs } from "./common"
import { UseQueryOptions, useQuery } from "react-query"

export interface Product {
    productId: string
    name: string
    prices: {
        monthly: {
            amount: number
            link: string
        }
        annual: {
            amount: number
            link: string
        }
        package: {
            amount: number
            link: string
        }
    }

    type: 'subscription' | 'package'

    limits: {
        records: number
        exports: number
     }
}

export interface Subscription {
    start: number
    until: number
    product: Product
    records?: number | null | undefined
    exports?: number | null | undefined
}

export interface Usage {
    product: Product
    usage: {
        exports: number
        records: number
    }
}

const getProducts = async () : Promise<Product[]> => {
    const { data } = await axios.get<Product[]>(`${pavukApi}/v1/billing/products?mode=test`)
    return data
}

export const useProducts = (options?: UseQueryOptions<Product[], ApiError>) => {
    const defaults =
    {
        staleTime: twentyFourHoursInMs,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        retry: true
    }
    
    return useQuery<Product[], ApiError>('products', getProducts, { ...options, ...defaults })
}

const getProduct = async (productId: string) : Promise<Product> => {
    const { data } = await axios.get<Product>(`${pavukApi}/v1/billing/products/${productId}`)
    return data
}

export const useProduct = (productId: string, options?: UseQueryOptions<Product, ApiError>) => {
    const defaults =
    {
        staleTime: twentyFourHoursInMs,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        retry: true
    }
    
    return useQuery<Product, ApiError>(['product', productId], async ()=>{
        return await getProduct(productId);
    }, { ...options, ...defaults })
}

export const useFreeProduct = (options?: UseQueryOptions<Product, ApiError>) => {
    const defaults =
    {
        staleTime: twentyFourHoursInMs,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        retry: true
    }
    
    return useQuery<Product, ApiError>(['product', 'free'], async ()=>{
        return await getProduct('free');
    }, { ...options, ...defaults })
}

const getSubscription = async () : Promise<Subscription> => {
    const { data } = await axios.get<Subscription>(`${pavukApi}/v1/billing/subscription`)
    return data
}

export const useSubscription = (options?: UseQueryOptions<Subscription, ApiError>) => {
    const defaults =
    {
        staleTime: 5000,
        refetchOnWindowFocus: false,
        retry: true
    }
    
    return useQuery<Subscription, ApiError>('subscription', getSubscription, { ...options, ...defaults })
}

const getUsage = async () : Promise<Usage> => {
    const { data } = await axios.get(`${pavukApi}/v1/billing/usage`)
    return data
}

export const useUsage = (options?: UseQueryOptions<Usage, ApiError>) => {
    const defaults =
    {
        staleTime: 10000,
        refetchInterval: 10000,
        refetchOnWindowFocus: true,
        refetchOnMount: true,
        refetchOnReconnect: true,
        retry: true
    }
    
    return useQuery<Usage, ApiError>('usage', getUsage, { ...options, ...defaults })
}