import * as React from 'react';
import GlobalStyles from '@mui/joy/GlobalStyles';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Checkbox from '@mui/joy/Checkbox';
import FormControl from '@mui/joy/FormControl';
import FormLabel, { formLabelClasses } from '@mui/joy/FormLabel';
import Link from '@mui/joy/Link';
import Input from '@mui/joy/Input';
import Typography from '@mui/joy/Typography';
import Stack from '@mui/joy/Stack';
import { PavukLogoIcon } from 'components/icons/PavukLogoIcon';
import ColorSchemeToggle from 'components/ColorSchemeToggle/ColorSchemeToggle';
import { setAuthToken, useAuth } from 'context/Auth';
import { useNavigate } from 'react-router';

import { useMutation } from 'react-query';
import { AuthOptions, PasswordChangeOptions, authorizeUser, startPasswordChange } from 'api/users';
import { useSearchParams } from 'react-router-dom';
import FormHelperText from '@mui/joy/FormHelperText';
import { useState } from 'react';
import { CheckCircleOutline } from '@mui/icons-material';

interface StartChangeFormElements extends HTMLFormControlsCollection {
    email: HTMLInputElement;
}

interface StartPasswordChangeForm extends HTMLFormElement {
    readonly elements: StartChangeFormElements;
}

export default function StartPasswordChangePage() {
    const [emailSent, setEmailStatus] = useState(false)
    const [error, setError] = useState<string | null>(null)

    const { isLoading: isSaving, mutateAsync: changePassword } = useMutation(startPasswordChange, {
        onSuccess: (data) => {
            setEmailStatus(true)
        },
        onError: (data) => {
            setError('We were unable to send password change link. Please, check email address and try again')
        }
    })

    return (
        <>
            <Box sx={{ display: 'flex', minHeight: '100dvh' }}>
                <Box
                    sx={{
                        pt: {
                            xs: 2,
                            sm: 2,
                            md: 2,
                        },
                        pb: {
                            xs: 2,
                            sm: 2,
                            md: 2,
                        },
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: 0,
                        height: '100dvh',
                        gap: 1,
                        overflow: 'auto',
                        '& form': {
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                        }
                    }}
                >
                    <Box
                        component="main"
                        sx={{
                            my: 'auto',
                            py: 2,
                            pb: 5,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                            width: 400,
                            maxWidth: '100%',
                            mx: 'auto',
                            borderRadius: 'sm',
                            '& form': {
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                            },
                            [`& .${formLabelClasses.asterisk}`]: {
                                visibility: 'hidden',
                            },
                        }}
                    >
                        {
                            emailSent ?
                                <>
                                    <Box>
                                        <Typography
                                            variant="soft"
                                            color="success"
                                            startDecorator={<CheckCircleOutline />}
                                            fontSize="sm"
                                            sx={{ '--Typography-gap': '0.5rem', p: 1 }}
                                        >
                                            We sent a link to continue password change to your email. Please, check your inbox
                                        </Typography>
                                    </Box>
                                </>
                                :
                                <><Stack gap={4} sx={{ mb: 2 }}>
                                    <Stack gap={1}>
                                        <Typography level="h3">Change password</Typography>
                                        <Typography level="body-sm">
                                            Changed your mind?{' '}
                                            <Link href="/login" level="title-sm">
                                                Sign in instead
                                            </Link>
                                        </Typography>
                                    </Stack>
                                </Stack>
                                    <Stack gap={4} sx={{ mt: 2 }}>
                                        <form
                                            onSubmit={(event: React.FormEvent<StartPasswordChangeForm>) => {
                                                event.preventDefault();
                                                const formElements = event.currentTarget.elements;

                                                const data: PasswordChangeOptions = {
                                                    email: formElements.email.value
                                                };

                                                changePassword(data)
                                            }}
                                        >
                                            <FormControl required>
                                                <FormLabel>Email</FormLabel>
                                                {
                                                    error ?

                                                        <Typography
                                                            variant="soft"
                                                            color="danger"
                                                            startDecorator="🚨"
                                                            fontSize="sm"
                                                            sx={{ '--Typography-gap': '0.5rem', p: 1 }}
                                                        >
                                                            {error}
                                                        </Typography>
                                                        : <></>
                                                }
                                                <FormHelperText>Use your account email. We will send message with a link to continue password change</FormHelperText>
                                                <Input type="email" name="email" disabled={isSaving} slotProps={{ input: { 'maxLength': 50 } }} />
                                            </FormControl>
                                            <Stack gap={4} sx={{ mt: 2 }}>
                                                <Button type="submit" fullWidth disabled={isSaving}>
                                                    {isSaving ? 'Please wait...' : 'Send change request'}
                                                </Button>
                                            </Stack>
                                        </form>
                                    </Stack></>
                        }
                    </Box>
                    <Box component="footer" sx={{ py: 3 }}>
                        <Typography level="body-xs" textAlign="center">
                            Pavuk AI © {new Date().getFullYear()} | A SemanticForce Company
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </>
    );
}