import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { ProtectedPage } from 'ProtectedPage'
import SignInPage from 'pages/Security/SignInPage'
import { FlowsPage } from 'pages/Flows/FlowsPage'
import SignUpPage from 'pages/Security/SignUpPage'
import { ProfileSetupPage } from 'pages/Security/ProfileSetupPage'
import { FlowProgress, FlowSetup } from 'pages/Flows/FlowSetup'
import { FlowStart } from 'pages/Flows/FlowStart'
import { BillingPage } from 'pages/Billing/BillingPage'
import { SessionsPage } from 'pages/Archive/SessionsPage'
import StartPasswordChangePage from 'pages/Security/PasswordChangePage'
import PasswordSavePage from 'pages/Security/PasswordSavePage'
import { PaymentSuccessPage } from 'pages/Billing/PaymentSuccessPage'
import AuthPage from 'pages/Security/Auth'

export const AppRoutes = ({ onModeChange }: { onModeChange: (mode: string) => void }) => {
    return (
        <Routes>
            <Route path="/" element={<ProtectedPage onModeChange={onModeChange}><FlowsPage /></ProtectedPage>} /> 
            <Route path="/profile" element={<ProfileSetupPage />} />   
            <Route path="/password/edit" element={<StartPasswordChangePage />} />   
            <Route path="/password/change" element={<PasswordSavePage />} />  
            <Route path="/billing" element={<ProtectedPage onModeChange={onModeChange}><BillingPage /></ProtectedPage>} /> 
            <Route path="/payment/success" element={<ProtectedPage onModeChange={onModeChange}><PaymentSuccessPage /></ProtectedPage>} /> 
            <Route path="/flows" element={<ProtectedPage onModeChange={onModeChange}><FlowsPage /></ProtectedPage>} />
            <Route path="/downloads" element={<ProtectedPage onModeChange={onModeChange}><SessionsPage /></ProtectedPage>} />
            <Route path="/flows/setup/:flowId" element={<ProtectedPage onModeChange={onModeChange}><FlowSetup /></ProtectedPage>} />
            <Route path="/flows/setup/:flowId/:sessionId" element={<ProtectedPage onModeChange={onModeChange}><FlowProgress /></ProtectedPage>} />
            <Route path="/flows/start/:flowId" element={<ProtectedPage onModeChange={onModeChange}><FlowStart /></ProtectedPage>} />
            <Route path="/apps" element={<ProtectedPage onModeChange={onModeChange}><FlowsPage /></ProtectedPage>} />
            <Route path="/apps/setup/:flowId" element={<ProtectedPage onModeChange={onModeChange}><FlowSetup /></ProtectedPage>} />
            <Route path="/apps/setup/:flowId/:sessionId" element={<ProtectedPage onModeChange={onModeChange}><FlowProgress /></ProtectedPage>} />
            <Route path="/apps/start/:flowId" element={<ProtectedPage onModeChange={onModeChange}><FlowStart /></ProtectedPage>} />
            <Route path="/login" element={<SignInPage />} />
            <Route path="/join" element={<SignUpPage />} />
            <Route path="/auth" element={<AuthPage />} />
        </Routes>
    )
}