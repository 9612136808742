import * as React from 'react';
import GlobalStyles from '@mui/joy/GlobalStyles';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Checkbox from '@mui/joy/Checkbox';
import FormControl from '@mui/joy/FormControl';
import FormLabel, { formLabelClasses } from '@mui/joy/FormLabel';
import Link from '@mui/joy/Link';
import Input from '@mui/joy/Input';
import Typography from '@mui/joy/Typography';
import Stack from '@mui/joy/Stack';
import { PavukLogoIcon } from 'components/icons/PavukLogoIcon';
import ColorSchemeToggle from 'components/ColorSchemeToggle/ColorSchemeToggle';
import { setAuthToken, useAuth } from 'context/Auth';
import { useNavigate } from 'react-router';

import { useMutation } from 'react-query';
import { AuthOptions, PasswordChangeOptions, PasswordSaveOptions, authorizeUser, changePassword, startPasswordChange } from 'api/users';
import { useSearchParams } from 'react-router-dom';
import FormHelperText from '@mui/joy/FormHelperText';
import { useState } from 'react';
import { CheckCircleOutline } from '@mui/icons-material';
import { RedirectToPage } from './RedirectTo';

interface SavePasswordFormElements extends HTMLFormControlsCollection {
    password: HTMLInputElement;
}

interface StartPasswordChangeForm extends HTMLFormElement {
    readonly elements: SavePasswordFormElements;
}

export default function PasswordSavePage() {
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState<string | null>(null)

    const { isLoading: isSaving, mutateAsync: savePassword } = useMutation(changePassword, {
        onSuccess: (data) => {
            setSuccess(true)
        },
        onError: (data) => {
            setError('We were unable to change password. Please, check your confirmation link or try again')
        }
    })
    const [searchParams] = useSearchParams()
    const email = searchParams.get('em')
    const code = searchParams.get('code')

    if (!email || !code)
        return (<RedirectToPage path='/password/edit' mode='location' />)

    return (

        <>
            <Box sx={{ display: 'flex', minHeight: '100dvh' }}>
                <Box
                    sx={{
                        pt: {
                            xs: 2,
                            sm: 2,
                            md: 2,
                        },
                        pb: {
                            xs: 2,
                            sm: 2,
                            md: 2,
                        },
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: 0,
                        height: '100dvh',
                        gap: 1,
                        overflow: 'auto',
                        '& form': {
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                        }
                    }}
                >
                    <Box
                        component="main"
                        sx={{
                            my: 'auto',
                            py: 2,
                            pb: 5,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                            width: 400,
                            maxWidth: '100%',
                            mx: 'auto',
                            borderRadius: 'sm',
                            '& form': {
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                            },
                            [`& .${formLabelClasses.asterisk}`]: {
                                visibility: 'hidden',
                            },
                        }}
                    >
                        {
                            success ?
                                <>
                                    <Stack gap={2} sx={{
                                        display: 'flex',
                                        alignContent: 'center',
                                        flex: 1,
                                        alignItems:'center'
                                    }}>
                                        <Typography
                                            variant="soft"
                                            color="success"
                                            startDecorator={<CheckCircleOutline />}
                                            fontSize="sm"
                                            sx={{ '--Typography-gap': '0.5rem', p: 1 }}
                                        >
                                            Your new password saved.
                                            Use it to access Pavuk AI account
                                        </Typography>
                                        <Box>
                                        <Link href="/login" level="title-sm" sx={{
                                            textAlign: 'center',
                                            width: '100%'
                                        }}>
                                            Open login page
                                        </Link></Box>
                                    </Stack>
                                </>
                                :
                                <><Stack gap={4} sx={{ mb: 2 }}>
                                    <Stack gap={1}>
                                        <Typography level="h3">Set new password</Typography>
                                        <Typography level="body-sm">
                                            Changed your mind?{' '}
                                            <Link href="/login" level="title-sm">
                                                Sign in instead
                                            </Link>
                                        </Typography>
                                    </Stack>
                                </Stack>
                                    <Stack gap={4} sx={{ mt: 2 }}>
                                        <form
                                            onSubmit={(event: React.FormEvent<StartPasswordChangeForm>) => {
                                                event.preventDefault();
                                                const formElements = event.currentTarget.elements;

                                                const data: PasswordSaveOptions = {
                                                    email: email,
                                                    code: code,
                                                    password: formElements.password.value
                                                };

                                                savePassword(data)
                                            }}
                                        >
                                            <FormControl required>
                                                <FormLabel>New password</FormLabel>
                                                {
                                                    error ?

                                                        <Typography
                                                            variant="soft"
                                                            color="danger"
                                                            startDecorator="🚨"
                                                            fontSize="sm"
                                                            sx={{ '--Typography-gap': '0.5rem', p: 1 }}
                                                        >
                                                            {error}
                                                        </Typography>
                                                        : <></>
                                                }
                                                <FormHelperText>Use secure password with minimum 6 symbols</FormHelperText>
                                                <Input type="password" required={true} name="password" disabled={isSaving} slotProps={{ input: { 'maxLength': 50 } }} />
                                            </FormControl>
                                            <Stack gap={4} sx={{ mt: 2 }}>
                                                <Button type="submit" fullWidth disabled={isSaving}>
                                                    {isSaving ? 'Please wait...' : 'Save password'}
                                                </Button>
                                            </Stack>
                                        </form>
                                    </Stack></>
                        }
                    </Box>
                    <Box component="footer" sx={{ py: 3 }}>
                        <Typography level="body-xs" textAlign="center">
                            Pavuk AI © {new Date().getFullYear()} | A SemanticForce Company
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </>
    );
}